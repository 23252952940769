/* eslint-disable @typescript-eslint/no-var-requires */
import { loadEnvConfig } from '@next/env';
import merge from 'lodash.merge';
import getNextConfig from 'next/config';
const defaultConfig = require('../../../config/env/default');
const liveConfig = require('../../../config/env/live');
const stagingConfig = require('../../../config/env/staging');
const localConfig = require('../../../config/env/local');
const projectDir = process.cwd();

const envConfig = (ENV) => {
  if (ENV === 'live') return liveConfig;
  if (ENV === 'staging') return stagingConfig;
  return localConfig;
};

type configEnv = 'non-test' | 'test';

export const getConfig = (configEnv: configEnv = 'non-test') => {
  try {
    if (configEnv === 'non-test') return getNextConfig();

    if (configEnv === 'test') {
      const projectConfigs = loadEnvConfig(projectDir).combinedEnv;

      const {
        AWS_ACCESS_KEY,
        AWS_SECRET_KEY,
        CLOUDINARY_KEY,
        CLOUDINARY_SECRET,
        ENV,
        EXPORTS_STAGING_BUCKET,
        EXPORTS_PROD_BUCKET,
        FROALA_KEY,
        DELIGHTED_API_KEY,
        DELIGHTED_API_KEY_TWO,
        SLACK_API_KEY,
        GEOLYTIX_API_URI,
        GEOLYTIX_V_4_URI,
        GEOLYTIX_DATABASE_HOST,
        GEOLYTIX_DATABASE_NAME,
        GEOLYTIX_DATABASE_PASSWORD,
        GEOLYTIX_DATABASE_PORT,
        GEOLYTIX_DATABASE_USERNAME,
        DWH_DATABASE_NAME,
        DWH_DATABASE_USER,
        DWH_DATABASE_PASSWORD,
        DWH_DATABASE_PORT,
        DWH_DATABASE_HOST,
        MONGO_TEST_URL,
        MONGO_URL,
        NODE_ENV,
        SENTRY_DSN,
        SENDGRID_SYNTAX_VALIDATION_KEY,
        GET_ADDRESS_API_KEY,
        MAPBOX_TOKEN,
        HASH_SECRET_PROD,
        HASH_SECRET_STAGING,
        API_PROD_URL,
        API_STAGING_URL,
        API_TEST_URL,
        JWT_SECRET,
        API_JWT_SECRET,
        TRANSLATIONS_API_URI,
        DATA_IMPORTER_URI,
        NEXTAUTH_SECRET,
        DESCOPE_PROJECT_ID,
        DESCOPE_ACCESS_KEY,
        GLEAP_API_SECRET,
        API_SECRET,
        HELPSCOUT_GENERAL_BEACON_ID,
        PDF_CLIPPER_URI,
      } = projectConfigs;

      const config = merge(defaultConfig, envConfig(ENV));

      const aws = {
        region: 'eu-west-2',
        accessKey: AWS_ACCESS_KEY,
        secretKey: AWS_SECRET_KEY,
        exportsBucket:
          ENV === 'live' ? EXPORTS_PROD_BUCKET : EXPORTS_STAGING_BUCKET,
        ...config.aws,
      };

      const descope = {
        nextAuthSecret: NEXTAUTH_SECRET,
        descopeProjectId: DESCOPE_PROJECT_ID,
        descopeAccessKey: DESCOPE_ACCESS_KEY,
      };
      const apiUrls = {
        prodUrl: API_PROD_URL,
        stagingUrl: API_STAGING_URL,
        testUrl: API_TEST_URL,
      };

      const env = {
        SENTRY_DSN,
        GEOLYTIX_API_URI,
      };

      const serverRuntimeConfig = {
        aws,
        database: config.database,
        env: ENV,
        hashSecret: ENV === 'live' ? HASH_SECRET_PROD : HASH_SECRET_STAGING,
        jwt: { JWT_SECRET },
        geolytix: {
          url: GEOLYTIX_API_URI,
          urlV4: GEOLYTIX_V_4_URI,
          database: {
            database: GEOLYTIX_DATABASE_NAME,
            user: GEOLYTIX_DATABASE_USERNAME,
            password: GEOLYTIX_DATABASE_PASSWORD,
            host: GEOLYTIX_DATABASE_HOST,
            port: Number(GEOLYTIX_DATABASE_PORT) || 5432,
          },
        },
        dwh: {
          database: {
            database: DWH_DATABASE_NAME,
            user: DWH_DATABASE_USER,
            password: DWH_DATABASE_PASSWORD,
            host: DWH_DATABASE_HOST,
            port: Number(DWH_DATABASE_PORT),
          },
        },
        mongodbUri:
          ENV === 'cypress'
            ? MONGO_TEST_URL
            : NODE_ENV === 'test'
            ? 'mongodb://127.0.0.1'
            : MONGO_URL,
        nodeEnv: NODE_ENV,
        session: config.session,
        delighted: DELIGHTED_API_KEY,
        delighted2: DELIGHTED_API_KEY_TWO,
        slack: SLACK_API_KEY,
        sendgrid: {
          validation: SENDGRID_SYNTAX_VALIDATION_KEY,
        },
        getAddress: GET_ADDRESS_API_KEY,
        getMapboxToken: MAPBOX_TOKEN,
        inviteSecret: API_JWT_SECRET,
        cloudinary: {
          apiKey: CLOUDINARY_KEY,
          apiSecret: CLOUDINARY_SECRET,
        },
        translation: {
          apiUrl: TRANSLATIONS_API_URI,
        },
        gleap: GLEAP_API_SECRET,
        descope,
        apiSecret: API_SECRET,
        mapboxToken: MAPBOX_TOKEN,
      };

      const publicRuntimeConfig = {
        apiUrls,
        dataImporterUri: DATA_IMPORTER_URI,
        pdfClipperUri: PDF_CLIPPER_URI,
        env: ENV,
        froala: {
          key: FROALA_KEY,
        },
        geolytix: {
          url: GEOLYTIX_API_URI,
          urlV4: GEOLYTIX_V_4_URI,
        },
        nodeEnv: NODE_ENV,
        helpscout: {
          general: HELPSCOUT_GENERAL_BEACON_ID,
        },
      };

      return { env, serverRuntimeConfig, publicRuntimeConfig };
    }
  } catch (error) {
    console.error('src/server/utils/getConfig.ts error: ', error);
  }
};

// export default getConfig;
