export const EDIT_MODE_MIN_WIDTH = 1024;

export enum EDITABLE_CONTENT_BLOCK_TYPES {
  ACCORDION = 'accordion',
  HERO = 'hero',
  TEXT = 'text',
  TITLE = 'title',
  INPUT = 'input',
  PROPOSALS = 'proposals', // TODO: Update when proposals edit mode is handled
  DESCRIPTION = 'description',
  IMAGE = 'image',
  QUESTION = 'question',
  MILESTONE = 'milestone', // Milestones page
  MILESTONES = 'milestones', // Hub page
  PROGRAMME = 'programme',
  DYNAMIC_PROPOSALS = 'dynamicProposals',
  IMAGE_COMPARISON = 'image-comparison',
  NAVIGATIONAL_MAP = 'navigationalMap',
  NAV = 'nav',
  PROPOSAL_MAP = 'proposalMap',
  PLANNING_APPS = 'planningapps',
  MEDIA_UPLOAD = 'mediaUpload',
  MATRIX = 'matrix',
  RESPONDENT_FILE_UPLOAD_QUESTION = 'respondentFileUpload',
  NEWS = 'news',
  TEAM = 'team',
  TWO_COLUMNS = 'twoColumns',
  CONTACT_TEAM = 'contactTeam',
  PROJECT_TEAM_HEADER = 'projectTeamHeader',
  ORGANISATION = 'organisation',
  DEFAULT_CONSENT = 'defaultConsent',
  CUSTOM_CONSENT = 'customConsent',
  DEMOGRAPHICS_QUESTION = 'demographicsQuestion',
  SPECIAL_DEMOGRAPHICS_QUESTION = 'specialDemographicsQuestion',
  IMAGE_MAP_PRO = 'imageMapPro',
  PROPOSAL_DYNAMIC_PROPOSALS = 'proposalDynamicProposals',
  IMAGE_AND_GRID = 'imageAndGrid',
}

export const EDITABLE_BLOCKS_TITLE = {
  [EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION]: 'Content: Accordion',
  [EDITABLE_CONTENT_BLOCK_TYPES.HERO]: 'Home Banner',
  [EDITABLE_CONTENT_BLOCK_TYPES.TEXT]: 'Content: Text',
  [EDITABLE_CONTENT_BLOCK_TYPES.INPUT]: 'Content: Title',
  [EDITABLE_CONTENT_BLOCK_TYPES.PROPOSALS]: 'Content: Tiles', // TODO: Update when proposals edit mode is handled
  [EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION]: 'Content: Text',
  [EDITABLE_CONTENT_BLOCK_TYPES.IMAGE]: 'Content: Image',
  [EDITABLE_CONTENT_BLOCK_TYPES.QUESTION]: 'Question',
  [EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE]: 'Milestone', // Milestones page
  [EDITABLE_CONTENT_BLOCK_TYPES.MILESTONES]: 'Content: Milestones', // Hub page
  [EDITABLE_CONTENT_BLOCK_TYPES.PROGRAMME]: 'Content: Projects',
  [EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS]: 'Content: Tiles',
  [EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON]: 'Media: Before/After Image',
  [EDITABLE_CONTENT_BLOCK_TYPES.NAVIGATIONAL_MAP]: 'Content: Embedded Map',
  [EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_MAP]: 'Content: Embedded Map',
  [EDITABLE_CONTENT_BLOCK_TYPES.PLANNING_APPS]: 'Planningapps',
  [EDITABLE_CONTENT_BLOCK_TYPES.MEDIA_UPLOAD]: 'Media: Media Upload',
  [EDITABLE_CONTENT_BLOCK_TYPES.RESPONDENT_FILE_UPLOAD_QUESTION]:
    'Question: Media submission',
  [EDITABLE_CONTENT_BLOCK_TYPES.NEWS]: 'News',
  [EDITABLE_CONTENT_BLOCK_TYPES.TEAM]: 'Team',
  [EDITABLE_CONTENT_BLOCK_TYPES.CONTACT_TEAM]: 'Content: Contact team',
  [EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER]: 'Project Team Header',
  [EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION]: 'Organisation',
  [EDITABLE_CONTENT_BLOCK_TYPES.DEFAULT_CONSENT]: 'Default Consent',
  [EDITABLE_CONTENT_BLOCK_TYPES.CUSTOM_CONSENT]: 'Custom Consent',
  [EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION]: 'Demographics Question',
  [EDITABLE_CONTENT_BLOCK_TYPES.SPECIAL_DEMOGRAPHICS_QUESTION]:
    'Special Demographics Question',
  [EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO]: 'Media: Interactive image',
  [EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_DYNAMIC_PROPOSALS]: 'Tiles',
  [EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_AND_GRID]: 'Media: Image',
  [EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS]: 'Content: Two Columns',
  [EDITABLE_CONTENT_BLOCK_TYPES.NAV]: 'Nav bar',
  [EDITABLE_CONTENT_BLOCK_TYPES.TITLE]: 'Content: title',
};
export const editableBlockTypes = [
  EDITABLE_CONTENT_BLOCK_TYPES.HERO,
  EDITABLE_CONTENT_BLOCK_TYPES.TEXT,
  EDITABLE_CONTENT_BLOCK_TYPES.TITLE,
  EDITABLE_CONTENT_BLOCK_TYPES.INPUT,
  EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION,
  EDITABLE_CONTENT_BLOCK_TYPES.QUESTION,
  EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE,
  EDITABLE_CONTENT_BLOCK_TYPES.PROGRAMME,
  EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS,
  EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON,
  EDITABLE_CONTENT_BLOCK_TYPES.MEDIA_UPLOAD,
  EDITABLE_CONTENT_BLOCK_TYPES.CONTACT_TEAM,
  EDITABLE_CONTENT_BLOCK_TYPES.RESPONDENT_FILE_UPLOAD_QUESTION,
  EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER,
  EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION,
  EDITABLE_CONTENT_BLOCK_TYPES.DEFAULT_CONSENT,
  EDITABLE_CONTENT_BLOCK_TYPES.CUSTOM_CONSENT,
  EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION,
  EDITABLE_CONTENT_BLOCK_TYPES.SPECIAL_DEMOGRAPHICS_QUESTION,
  EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO,
  EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_DYNAMIC_PROPOSALS,
  EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_AND_GRID,
  EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION,
  EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS,
  EDITABLE_CONTENT_BLOCK_TYPES.NAVIGATIONAL_MAP,
  EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_MAP,
  EDITABLE_CONTENT_BLOCK_TYPES.NAV,
];

export const HUB_PAGE_ACTIONS = {
  INIT_HUB_PAGE: 'INIT_HUB_PAGE',
  UPDATE_SECTIONS: 'HUB_UPDATE_SECTIONS',
  UPDATE_TEXT: 'UPDATE_TEXT',
  UPDATE_HERO: 'UPDATE_HERO',
  UPDATE_NAV_MAP: 'UPDATE_NAV_MAP',
  TOGGLE_SECTION: 'TOGGLE_SECTION',
  UPDATE_RULES: 'UPDATE_RULES',
  UPDATE_SECTION_CONTENT: 'UPDATE_SECTION_CONTENT',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  DUPLICATE_BLOCK: 'DUPLICATE_BLOCK',
  UPDATE_DYNAMIC_PROPOSALS: 'UPDATE_DYNAMIC_PROPOSALS',
  UPDATE_HUB_BLOCK: 'UPDATE_HUB_BLOCK',
};

export const EDIT_LAYOUT_ACTIONS = {
  SWITCH_DEVICE_PREVIEW: 'SWITCH_DEVICE_PREVIEW',
};

export enum EDITABLE_PAGE_TYPES {
  HUB = 'hub',
  PROPOSAL = 'proposal',
  MILESTONES = 'milestones',
  SUMMARY = 'summary',
  TIMELINE = 'timeline',
  MAP = 'map',
  TEAMS = 'teams',
  PREFERENCES = 'preferences',
  DEMOGRAPHICS = 'demographics',
  PROPOSALS = 'proposals',
}

export enum DROPPABLE_IDS {
  PROPOSAL = 'PROPOSAL_SECTION_ITEMS',
  MILESTONES = 'MILESTONES_SECTION_ITEMS',
  HUB = 'HUB_SECTION_ITEMS',
  TEAMS = 'TEAMS_SECTION_ITEMS',
  PREFERENCES = 'PREFERENCES_SECTION_ITEMS',
  DEMOGRAPHICS = 'DEMOGRAPHICS_SECTION_ITEMS',
  PROPOSALS = 'PROPOSALS_SECTION_ITEMS',
}

export const PROPOSAL_BLOCKS_ACTIONS = {
  INITIALIZE_CONTENT_BLOCKS: 'INITIALIZE_CONTENT_BLOCKS',
  UPDATE_IMAGE_COMPARISON: 'UPDATE_IMAGE_COMPARISON',
  UPDATE_IMAGE_MAP_PRO: 'UPDATE_IMAGE_MAP_PRO',
  UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
  UPDATE_TITLE: 'UPDATE_TITLE',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  UPDATE_MEDIA_UPLOAD: 'UPDATE_MEDIA_UPLOAD',
  DUPLICATE_BLOCK: 'DUPLICATE_BLOCK',
  UPDATE_DYNAMIC_PROPOSALS: 'UPDATE_DYNAMIC_PROPOSALS',
  UPDATE_PROPOSAL_MAP: 'UPDATE_PROPOSAL_MAP',
};

export const MILESTONES_BLOCKS_ACTIONS = {
  UPDATE_CONTENT_BLOCKS: 'UPDATE_CONTENT_BLOCKS',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  DUPLICATE_BLOCK: 'DUPLICATE_BLOCK',
};

export const MILESTONES_PAGE_ACTIONS = {
  INIT_MILESTONES_PAGE: 'INIT_MILESTONES_PAGE',
  UPDATE_MILESTONE_ITEM: 'UPDATE_MILESTONE_ITEM',
  UPDATE_MILESTONE_CONTENT: 'UPDATE_MILESTONE_CONTENT',
  INITIALIZE_CONTENT: 'INITIALIZE_CONTENT',
  UPDATE_ITEM_STATES: 'UPDATE_ITEM_STATES',
};

export const PROPOSAL_PAGE_ACTIONS = {
  UPDATE_PROPOSAL_VIEW: 'UPDATE_PROPOSAL_VIEW',
  UPDATE_PROPOSAL_CARD: 'UPDATE_PROPOSAL_CARD',
  UPDATE_PROPOSAL_STAGE: 'UPDATE_PROPOSAL_STAGE',
  UPDATE_PROPOSAL_SLUG: 'UPDATE_PROPOSAL_SLUG',
};
export const PROPOSALS_PAGE_ACTIONS = {
  INIT_PROPOSALS_PAGE: 'INIT_PROPOSALS_PAGE',
  UPDATE_BLOCK_CONTENT: 'UPDATE_BLOCK_CONTENT',
  UPDATE_BLOCKS: 'UPDATE_BLOCKS',
  DELETE_BLOCK: 'DELETE_BLOCK',
  ADD_BLOCK: 'ADD_BLOCK',
};

export const NAVBAR_ACTIONS = {
  INIT_NAVBAR: 'INIT_NAVBAR',
  UPDATE_NAVBAR_ITEMS: 'UPDATE_NAVBAR_ITEMS',
  UPDATE_NAVBAR_ITEM: 'UPDATE_NAVBAR_ITEM',
  DELETE_NAVBAR_ITEM: 'DELETE_NAVBAR_ITEM',
  ADD_NAVBAR_ITEM: 'ADD_NAVBAR_ITEM',
};

export const PROPOSAL_QUESTIONS_ACTIONS = {
  UPDATE_FULL_JSON: 'UPDATE_FULL_JSON',
  CLEAR_ALL: 'CLEAR_ALL',
  INITIALIZE_ALL: 'INITIALIZE_ALL',
  UPDATE_IMAGE_QUESTION: 'UPDATE_IMAGE_QUESTION',
};

export const METADATA_ACTIONS = {
  SET_STATE_LOADING_BUTTON: 'SET_STATE_LOADING_BUTTON',
  SET_FROALA_LOADED: 'SET_FROALA_LOADED',
  SET_EDIT_MAP_QUESTIONS: 'SET_EDIT_MAP_QUESTIONS',
};

export const MOBILE_VIEW = {
  bottomNavigationUrl:
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1619179004/Edit%20mode/Smarthphone%20view/bottom_navigation.png',
};

export const PROJECT_TEAM_PAGE_ACTIONS = {
  INIT_PROJECT_TEAM_PAGE: 'INIT_PROJECT_TEAM_PAGE',
  UPDATE_ORGANISATIONS: 'PROJECT_TEAM_UPDATE_ORGANISATIONS',
  UPDATE_BLOCKS: 'PROJECT_TEAM_UPDATE_BLOCKS',
  UPDATE_HEADER: 'UPDATE_HEADER',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  DUPLICATE_BLOCK: 'DUPLICATE_BLOCK',
};
export const PREFERENCES_BLOCK_ACTIONS = {
  UPDATE_CONTENT_BLOCKS: 'UPDATE_CONTENT_BLOCKS',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  DUPLICATE_BLOCK: 'DUPLICATE_BLOCK',
};

export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 40_000_000; //40mb
