/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { Action } from '@reduxjs/toolkit';
import {
  hubPageReducer,
  proposalBlocksReducer,
  proposalQuestionsReducer,
  metadataReducer,
  milestonesBlocksReducer,
  milestonesPageReducer,
  projectTeamPageReducer,
  preferencesBlocksReducer,
  editModeLayoutReducer,
  proposalsPageReducer,
  navbarReducer,
} from 'Pages/edit/reducers';
import { LoadingButtonStates } from 'Atoms/LoadingButton/constants';
import { MilestonesPageState } from 'Client/pages/edit/reducers/milestonesPageReducer';

const DEFAULT_STATE_HUB_PAGE = {
  _id: '',
  type: '',
  projectId: '',
  active: true,
  content: {
    title: '',
    description: '',
    sections: [],
    navbar: [],
  },
};

const DEFAULT_STATE_MILESTONES_BLOCKS = [];
const DEFAULT_STATE_PREFERENCES_BLOCKS = [];
const DEFAULT_STATE_PROPOSAL_BLOCKS = [];

const DEFAULT_STATE_MILESTONES_PAGE = {
  title: '',
  description: '',
  milestones: [],
} as MilestonesPageState;
const DEFAULT_STATE_PROPOSAL_PAGE = {};
const DEFAULT_STATE_PROPOSAL_QUESTIONS = {};
const DEFAULT_STATE_METADATA = {
  buttonLoading: LoadingButtonStates.INITIAL,
  isFroalaLoaded: false,
};

const DEFAULT_STATE_PROJECT_TEAM_PAGE = {
  _id: '',
  type: '',
  content: {
    title: '',
    description: '',
  },
};

const DEFAULT_STATE_EDIT_LAYOUT = {
  editModeLayout: { previewMode: 'mobile' },
};

const DEFAULT_STATE_PROPOSALS_PAGE = {
  'en-GB': { blocks: [] },
};
const DEFAULT_STATE_NAVBAR = {
  'en-GB': { items: [] },
};

const EditModeContext = React.createContext({
  dispatchHub: (() => {}) as React.Dispatch<Action>,
  dispatchProposalBlocks: (() => {}) as React.Dispatch<Action>,
  dispatchQuestions: (() => {}) as React.Dispatch<Action>,
  dispatchMetadata: (() => {}) as React.Dispatch<Action>,
  dispatchMilestonesBlocks: (() => {}) as React.Dispatch<Action>,
  dispatchMilestonesPage: (() => {}) as React.Dispatch<Action>,
  dispatchProjectTeamPage: (() => {}) as React.Dispatch<Action>,
  dispatchPreferencesBlocks: (() => {}) as React.Dispatch<Action>,
  dispatchEditModeLayout: (() => {}) as React.Dispatch<Action>,
  dispatchProposalsPage: (() => {}) as React.Dispatch<Action>,
  dispatchNavbar: (() => {}) as React.Dispatch<Action>,
  state: {
    hubPage: { ...DEFAULT_STATE_HUB_PAGE },
    proposalBlocks: { ...DEFAULT_STATE_PROPOSAL_BLOCKS },
    proposalQuestions: { ...DEFAULT_STATE_PROPOSAL_PAGE },
    metadata: { ...DEFAULT_STATE_PROPOSAL_QUESTIONS },
    milestonesBlocks: { ...DEFAULT_STATE_MILESTONES_BLOCKS },
    milestonesPage: { ...DEFAULT_STATE_MILESTONES_PAGE },
    projectTeamPage: { ...DEFAULT_STATE_PROJECT_TEAM_PAGE },
    preferencesBlocks: { ...DEFAULT_STATE_PREFERENCES_BLOCKS },
    editModeLayout: { ...DEFAULT_STATE_EDIT_LAYOUT },
    proposalsPageState: { ...DEFAULT_STATE_PROPOSALS_PAGE },
    navbarState: { ...DEFAULT_STATE_NAVBAR },
  },
});

const EditModeContextProvider = ({ initialContextState, children }) => {
  const [hubPageState, dispatchHub] = React.useReducer(
    hubPageReducer,
    initialContextState.hub
  );
  const [proposalBlocksState, dispatchProposalBlocks] = React.useReducer(
    proposalBlocksReducer,
    initialContextState?.proposalBlocks || DEFAULT_STATE_PROPOSAL_BLOCKS
  );
  const [proposalQuestionsState, dispatchQuestions] = React.useReducer(
    proposalQuestionsReducer,
    initialContextState?.proposalQuestions || DEFAULT_STATE_PROPOSAL_QUESTIONS
  );
  const [metadataState, dispatchMetadata] = React.useReducer(
    metadataReducer,
    DEFAULT_STATE_METADATA
  );

  const [milestonesBlocksState, dispatchMilestonesBlocks] = React.useReducer(
    milestonesBlocksReducer,
    initialContextState?.milestonesBlocks || DEFAULT_STATE_MILESTONES_BLOCKS
  );
  const [preferencesBlocksState, dispatchPreferencesBlocks] = React.useReducer(
    preferencesBlocksReducer,
    initialContextState?.preferencesBlocks || DEFAULT_STATE_PREFERENCES_BLOCKS
  );

  const [milestonesPageState, dispatchMilestonesPage] = React.useReducer(
    milestonesPageReducer,
    initialContextState?.milestonesPage || DEFAULT_STATE_MILESTONES_PAGE
  );
  const [proposalsPageState, dispatchProposalsPage] = React.useReducer(
    proposalsPageReducer,
    initialContextState?.proposalsPage || DEFAULT_STATE_PROPOSALS_PAGE
  );
  const [navbarState, dispatchNavbar] = React.useReducer(
    navbarReducer,
    initialContextState?.navbar || DEFAULT_STATE_NAVBAR
  );
  const [projectTeamPageState, dispatchProjectTeamPage] = React.useReducer(
    projectTeamPageReducer,
    initialContextState?.projectTeamPage || DEFAULT_STATE_PROJECT_TEAM_PAGE
  );

  const [editModeLayoutState, dispatchEditModeLayout] = React.useReducer(
    editModeLayoutReducer,
    initialContextState?.editModeLayout || DEFAULT_STATE_EDIT_LAYOUT
  );

  return (
    <EditModeContext.Provider
      value={{
        dispatchHub,
        dispatchProposalBlocks,
        dispatchQuestions,
        dispatchMetadata,
        dispatchMilestonesBlocks,
        dispatchMilestonesPage,
        dispatchProjectTeamPage,
        dispatchPreferencesBlocks,
        dispatchEditModeLayout,
        dispatchProposalsPage,
        dispatchNavbar,
        state: {
          hubPage: hubPageState,
          proposalBlocks: proposalBlocksState,
          proposalQuestions: proposalQuestionsState,
          metadata: metadataState,
          milestonesBlocks: milestonesBlocksState,
          milestonesPage: milestonesPageState,
          projectTeamPage: projectTeamPageState,
          preferencesBlocks: preferencesBlocksState,
          editModeLayout: editModeLayoutState,
          proposalsPageState: proposalsPageState,
          navbarState: navbarState,
        },
      }}
    >
      {children}
    </EditModeContext.Provider>
  );
};

export { EditModeContext, EditModeContextProvider };
