import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'Atoms';
import { TypographyProps } from 'Atoms/Typography';

interface Props extends TypographyProps {
  value: string | number;
  isMap?: boolean;
  type?: string;
}

export const SingleValue: React.FC<Props> = ({
  value,
  isMap,
  type,
  ...props
}: Props) => {
  if (type === 'textarea' && typeof value === 'string' && value.match('\n')) {
    const newValue = value
      .split('\n')
      .filter((str) => Boolean(str.trim()))
      .map((line) => line)
      .join('\n');
    value = newValue;
  }
  return (
    <Label
      isMap={isMap}
      variant="body2"
      data-testid="SingleValueAnswerComponent"
      {...props}
    >
      {value}
    </Label>
  );
};

const Label = styled(({ isMap, ...props }) => <Typography {...props} />)<{
  isMap: boolean;
}>`
  ${({ isMap }) => isMap && 'font-size: 1rem'}
  white-space: pre-line;
`;
