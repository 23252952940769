import * as React from 'react';
import Head from 'next/head';
import { getConfig } from 'Shared/utils/getConfig';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { fromLonLat } from 'ol/proj';
import { MapZoomButton } from 'Molecules/MapZoomButton';
import { Helper3D } from 'Molecules/Helper3D';
import { Image3DView } from 'Molecules/Image3DView';
import { useProject, useMap, useUser } from 'Client/utils/hooks';
import { initXyz } from 'Client/services/geolytix';
import { loadScript } from 'Client/utils/loadScript';
import { CustomLayerHighlight } from 'Client/components/molecules/CustomLayerHighlight';
import { getContributionUserId } from 'Client/services/contributions';
import {
  CONTRIBUTION_SESSION_ITEM,
  getLocalItem,
} from 'Client/utils/localstorage';
import { fetchMapPage } from 'Client/services/map';
import { ListPagesParams } from 'Server/services/pages/types';
import { HubHeader } from '..';
import {
  MapSize,
  Image3DWrapper,
  Helper3DWrapper,
} from './NavigationalMap.style';
import { NavigationalMapContent, NavigationalMapProps } from './types';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const GEOLYTIX_URL =
  publicRuntimeConfig?.geolytix?.url || serverRuntimeConfig?.geolytix?.url;

const getMapPage = async (content: NavigationalMapContent) => {
  const params: ListPagesParams = {};

  if (content?.slug) {
    params.slug = content.slug;
  }

  if (content?.pageId) {
    params.id = content.pageId;
  }

  return await fetchMapPage(params);
};

export const NavigationalMapV3: React.FC<NavigationalMapProps> = ({
  content,
  header = true,
  view,
  hideZoomButtons = false,
  centered,
  index = 'nav-map',
  filter,
  isMapChart,
}) => {
  const {
    dispatch,
    state: { xyz, highlightedCustomLayer, mode, image3d },
  } = useMap();
  const project = useProject();
  const { user } = useUser();
  const { t } = useTranslation();
  const router = useRouter();

  React.useEffect(() => {
    console.info('Loading XYZ v3');

    const load = async () => {
      const { _xyz } = window;
      if (!_xyz) return;

      const { open } = window.XMLHttpRequest.prototype;
      const mapPage = await getMapPage(content);

      function openReplacement(...args) {
        if (args[1].startsWith(GEOLYTIX_URL)) {
          const separator = args[1].includes('?') ? '&' : '?';
          let params = '';
          if (!args[1].includes('lang=')) {
            params += `&lang=${router.locale}`;
          }
          if (!args[1].includes('project=')) {
            params += `&project=${project._id}`;
          }
          if (!args[1].includes('pageId=')) {
            params += `&pageId=${mapPage?._id}`;
          }
          args[1] = args[1] + `${separator}${params}`;
        }
        return open.apply(this, args);
      }

      window.XMLHttpRequest.prototype.open = openReplacement;

      const existingMap =
        isMapChart &&
        document.querySelectorAll(`#OL-${index} > div`).length === 1;

      console.log('existingMap: ', existingMap);

      if (existingMap) return;

      const OL = document.getElementById(`OL-${index}`);

      const xyz = await initXyz({
        target: OL,
        project: project._id,
        pageId: mapPage?._id,
        hooks: false,
        lang: router.locale,
      });

      if (content.pageId) xyz.layers.list['Contributions'].remove();
      xyz.layers.list['Postal district'].remove();

      if (!project.features.planningApps) xyz.layers.list['Satellite'].remove();
      if (!view?.display3d) xyz.layers.list['3d View'].remove();

      dispatch({
        type: 'INITIALIZE',
        payload: xyz,
      });

      dispatch({
        type: 'INITIALIZE_LAYERS',
        payload: {
          dispatch,
          page: { pageId: mapPage._id, ...mapPage },
          project,
          uniqueId: getContributionUserId({
            user,
            storedUserId: getLocalItem(CONTRIBUTION_SESSION_ITEM),
          }),
          lang: router.locale,
        },
      });

      // Close info panel
      dispatch({
        type: 'TOGGLE_INFO_PANEL',
      });

      return { mapPage, xyz };
    };

    Promise.all([
      loadScript({ url: `${GEOLYTIX_URL}/js/mapp.js`, id: 'mapp.js' }),
      loadScript({ url: `${GEOLYTIX_URL}/js/ol.js`, id: 'ol.js' }),
      loadScript({
        url: `${GEOLYTIX_URL}/js/maplibre-gl.js`,
        id: 'maplibre-gl.js',
      }),
    ])
      .then(load)
      .then((data) => {
        const pageId = data.mapPage?._id;

        // Adding the filters
        if (filter && filter.contributionsIds) {
          const { contributionsIds } = filter;

          const hasNestedFilters = router?.query?.nestedFilters?.length > 0;

          const filteredIds = contributionsIds
            .filter((contribution) => {
              return contribution.pageId === pageId;
            })
            .map((contribution) => contribution.contributionId);

          const contributionIdStage =
            filteredIds.length >= 300 && !hasNestedFilters
              ? {}
              : {
                  contribution_id: {
                    in: filteredIds,
                  },
                };

          const filters = [
            {
              project: {
                eq: project._id,
              },
              page_id: {
                eq: pageId,
              },
              ...contributionIdStage,
            },
          ];

          const layerName = 'Contributions';

          if (data.xyz.layers.list[layerName]) {
            data.xyz.layers.list[layerName].filter.current = filters.length
              ? JSON.stringify(filters)
              : null;

            data.xyz.layers.list[layerName].reload();
          }
        }
      })
      .finally(() => console.info('Map v3 enabled!', GEOLYTIX_URL));
  }, [content?.slug, filter]);

  React.useEffect(() => {
    if (centered && xyz) {
      const coords = fromLonLat([centered.lng, centered.lat]);
      xyz.map.getView().animate({ center: coords });
    }
  }, [centered, xyz]);

  const display3d = view?.display3d && mode === '3d';
  const showZoomButtons = !hideZoomButtons;

  const renderCustomLayerHighlight =
    highlightedCustomLayer &&
    highlightedCustomLayer.position?.x &&
    highlightedCustomLayer.position?.y &&
    highlightedCustomLayer?.hoverablePopup &&
    !isMapChart;

  return (
    <>
      <Head>
        {GEOLYTIX_URL && (
          <link rel="stylesheet" href={`${GEOLYTIX_URL}/views/_default.css`} />
        )}
      </Head>
      {header && <HubHeader title={content.title || t('Navigational Map')} />}
      <MapSize id="Map" data-testid="navigational-map">
        <MapSize id={`OL-${index}`} />
        {showZoomButtons && <MapZoomButton view={view} />}
      </MapSize>

      {display3d && !image3d && (
        <Helper3DWrapper>
          <Helper3D />
        </Helper3DWrapper>
      )}

      {display3d && image3d && (
        <Image3DWrapper>
          <Image3DView />
        </Image3DWrapper>
      )}

      {renderCustomLayerHighlight && <CustomLayerHighlight isNavigationalMap />}
    </>
  );
};
