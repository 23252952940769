import * as React from 'react';
import { MapContext } from 'Client/context/mapContext';
import { MapV4Context } from 'Client/context/mapV4Context';
import { ProposalContext } from 'Client/context/proposalContext';
import {
  ProposalAction,
  ProposalContextState,
} from 'Client/context/proposalReducer';
import { MapContextState } from 'Shared/types/map';
import { EditModeContext } from 'Client/context/editModeContext';
import { DemographicsContext } from 'Client/context/demographicsContext';
import { LoaderContext } from 'Client/context/loaderContext';
import { LoaderAction, LoaderContextState } from 'Client/context/loaderReducer';
import { useProject } from './useProject';

const useProposalContext = (): [
  ProposalContextState['proposal'],
  React.Dispatch<ProposalAction>,
] => {
  const { proposal, dispatch } = React.useContext(ProposalContext);
  return [proposal, dispatch];
};

export const useMapContextV3 = (): MapContextState => {
  const { state, dispatch } = React.useContext(MapContext);
  return { state: state, dispatch };
};

const useMapContextV4 = (): MapContextState => React.useContext(MapV4Context);

const useHubContext = (): {
  state: LoaderContextState;
  dispatch: React.Dispatch<LoaderAction>;
} => {
  const { dispatch, ...props } = React.useContext(LoaderContext);
  return { state: props, dispatch };
};

const useEditModeContext = () => {
  const {
    state,
    dispatchHub,
    dispatchProposalBlocks,
    dispatchQuestions,
    dispatchMetadata,
    dispatchMilestonesBlocks,
    dispatchMilestonesPage,
    dispatchProjectTeamPage,
    dispatchPreferencesBlocks,
    dispatchEditModeLayout,
    dispatchProposalsPage,
    dispatchNavbar,
  } = React.useContext<any>(EditModeContext);
  return [
    state,
    {
      dispatchHub,
      dispatchProposalBlocks,
      dispatchQuestions,
      dispatchMetadata,
      dispatchMilestonesBlocks,
      dispatchMilestonesPage,
      dispatchProjectTeamPage,
      dispatchPreferencesBlocks,
      dispatchEditModeLayout,
      dispatchProposalsPage,
      dispatchNavbar,
    },
  ];
};

const useDemographicsContext = () => {
  const {
    dispatchDemographics,
    specialAnswers,
    nonSpecialAnswers,
    demographicsAnswers,
  } = React.useContext(DemographicsContext);
  return {
    specialAnswers,
    nonSpecialAnswers,
    demographicsAnswers,
    dispatchDemographics,
  };
};

const useMap = (): MapContextState => {
  const {
    features: { useMapV4 },
  } = useProject();

  const version = useMapV4 ? 'v4' : 'v3';

  const v4 = useMapContextV4();
  const v3 = useMapContextV3();

  if (useMapV4) return { ...v4, version };

  return { ...v3, version };
};

export {
  useProposalContext,
  useHubContext,
  useEditModeContext,
  useDemographicsContext,
  useMap,
};
