import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { COOKIES } from 'Client/constants/cookies';
import { CustomerButton, Checkbox } from 'Molecules';
import {
  ButtonsWrapper,
  Description,
  EditModeModalProps,
} from 'Molecules/Modal';
import { Typography } from 'Atoms';
import { Modal, CheckboxWrapper } from './CheckFeelingQuestionModal.styles';

interface Props extends EditModeModalProps {
  currentSlug?: string;
}

type CallbackType = () => void;

export const CheckFeelingQuestionModal: React.FC<Props> = ({
  open,
  onClose,
  onSave,
  onDiscard,
  currentSlug,
}: Props) => {
  const [, setCookie] = useCookies();
  const [checked, setChecked] = React.useState(false);
  const { t } = useTranslation('customer');

  const handleCheckboxChange = (e: React.ChangeEvent<{ checked: boolean }>) => {
    setChecked(e.target.checked);
  };

  const applyCookie = () => {
    if (checked) {
      setCookie(COOKIES.HIDE_NO_SENTIMENT_WARNING, currentSlug);
    }
  };

  const handleClick = (callback: CallbackType) => {
    applyCookie();
    callback();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      data-testid="CheckFeelingQuestionModal"
    >
      <Typography variant="h3">{t('No sentiment question set')}</Typography>
      <Description>
        {t(
          'This means that contributions will not show an overall sentiment and dashboard graphs will not show sentiment.'
        )}
      </Description>
      <ButtonsWrapper>
        <CustomerButton
          inverse
          onClick={() => handleClick(onDiscard)}
          data-testid="discard-button"
        >
          {t('Return to editing')}
        </CustomerButton>
        <CustomerButton
          onClick={() => handleClick(onSave)}
          data-testid="save-button"
        >
          {t('Ignore and save')}
        </CustomerButton>
      </ButtonsWrapper>
      <CheckboxWrapper>
        <Checkbox
          label={t("Don't show again for this page")}
          onChange={handleCheckboxChange}
          checked={checked}
          customer
        />
      </CheckboxWrapper>
    </Modal>
  );
};
