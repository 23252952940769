import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'Atoms';
import { TypographyProps } from 'Atoms/Typography';

export const PageHeader: React.FC<TypographyProps> = ({
  ...props
}: TypographyProps) => <BasePageHeader {...props} />;

const BasePageHeader = styled(({ ...props }) => (
  <Typography variant="h1" {...props} />
))`
  font-size: 2.5rem;
  padding-top: 3rem;
`;
