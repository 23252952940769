import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerButton } from 'Molecules';
import {
  ButtonsWrapper,
  EditModeModal,
  Description,
  EditModeModalProps,
} from 'Molecules/Modal';
import { Typography } from 'Atoms';

export const PreventRouteModal: React.FC<EditModeModalProps> = ({
  open,
  onClose,
  onSave,
  onDiscard,
  customText,
}: EditModeModalProps) => {
  const { t } = useTranslation('customer');
  return (
    <EditModeModal
      open={open}
      onClose={onClose}
      data-testid="PreventRouteModal"
    >
      <Typography variant="h3">
        {customText?.header ||
          t(`You haven't saved your changes for this page yet.`)}
      </Typography>
      <Description>
        {customText?.description ||
          t('Do you want to save them before you leave?')}
      </Description>
      <ButtonsWrapper>
        <CustomerButton
          inverse
          onClick={onDiscard}
          data-testid="discard-button"
          data-onboarding="edit-mode-unsaved-changes-discard"
        >
          {customText?.cancel || t('Discard and continue')}
        </CustomerButton>
        <CustomerButton
          onClick={onSave}
          data-testid="save-button"
          data-onboarding="edit-mode-unsaved-changes-save"
        >
          {customText?.save || t('Save and continue')}
        </CustomerButton>
      </ButtonsWrapper>
    </EditModeModal>
  );
};
