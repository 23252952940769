/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { demographicsReducer } from './demographicsReducer';

const DEFAULT_STATE = {
  nonSpecialAnswers: [],
  specialAnswers: [],
  demographicsAnswers: [],
  dispatchDemographics: (() => {}) as React.Dispatch<
    React.SetStateAction<{ type: string; payload?: any }>
  >,
};

const DemographicsContext = React.createContext(DEFAULT_STATE);

const DemographicsContextProvider = ({ children }) => {
  const [state, dispatchDemographics] = React.useReducer(
    demographicsReducer,
    DEFAULT_STATE
  );

  return (
    <DemographicsContext.Provider
      value={{
        dispatchDemographics,
        specialAnswers: state.specialAnswers,
        nonSpecialAnswers: state.nonSpecialAnswers,
        demographicsAnswers: state.demographicsAnswers,
      }}
    >
      {children}
    </DemographicsContext.Provider>
  );
};

export { DemographicsContext, DemographicsContextProvider };
