import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';
import {
  ErrorStatusOrHelperText,
  FormControl as FormControlBase,
  FieldInfoTip,
  InputLabel,
} from 'Atoms';
import { editModeMobile } from 'Client/components/theme/utils';
import { TextFieldProps } from '.';
import { InputLoading as InputLoadingBase } from '../InputLoading';

const TextField: React.FC<TextFieldProps> = ({
  label,
  placeholder,
  handleChange,
  handleBlur,
  required,
  autoFocus,
  value,
  showLabel,
  status,
  type,
  width,
  disabled,
  isMap,
  rightIcon,
  inputId,
  infoTip,
  infoTipIcon,
  inputProps,
  loading,
  showPasswordOption,
  autocomplete,
  ...props
}) => (
  <FormControl isMap={isMap} variant="outlined" {...props} customWidth={width}>
    <Input
      inputId={props.id ?? inputId ?? label}
      showLabel={!showLabel}
      isMap={isMap}
    >
      {label}
    </Input>

    {rightIcon && rightIcon}
    <TextInput
      id={inputId || (label as string)}
      placeholder={placeholder}
      value={value as string}
      variant="outlined"
      onChange={handleChange}
      onBlur={handleBlur}
      required={required}
      autoFocus={autoFocus}
      error={status?.type === 'error'}
      type={type}
      disabled={disabled}
      inputProps={inputProps}
      loading={loading}
      InputProps={inputProps}
      defaultValue={props?.defaultValue}
      autoComplete={autocomplete}
      data-testid="text-field-input"
    />
    {loading && <InputLoading />}
    {status?.message && (
      <ErrorStatusOrHelperText
        aria-describedby={inputId || (label as string)}
        status={status}
      />
    )}
    {infoTip && <FieldInfoTip infoTip={infoTip} infoTipIcon={infoTipIcon} />}
  </FormControl>
);

const FormControl = styled(({ isMap, customWidth, ...props }) => (
  <FormControlBase {...props} />
))`
  ${({ theme, customWidth, isMap }) => css`
    ${isMap &&
    `
        display: flex;
        width: 100%;
      `}
    ${!isMap &&
    `
        width: ${customWidth || '75%'};
        min-width: ${customWidth || '35rem'} !important;
      `}
      ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        min-width: 100% !important;
      `
    )}
      .info-tip {
      display: none;
    }
    &:focus,
    &:focus-within {
      .info-tip {
        display: inline-block;
      }
    }
  `};
`;

const Input = styled(({ showLabel, ...props }) => <InputLabel {...props} />)`
  ${({ showLabel }) =>
    showLabel &&
    css`
      display: none;
    `}
`;

const TextInput = styled(MuiTextField)<{ loading?: boolean }>`
  input {
    padding: 0.9rem;
    order: 0;
    &:focus {
      border: 0.19rem solid ${({ theme }) => theme.colorMappings.focusHighlight};
      border-radius: 0.5rem;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 0.3125rem;
  }
  .MuiInputBase-input {
    ${({ loading }) =>
      loading &&
      css`
        margin-right: 1.875rem;
      `}
  }
  .Mui-focused {
    fieldset {
      &.MuiOutlinedInput-notchedOutline {
        border-top: 0 solid white;
        border-left: 0 solid white;
        border-right: 0 solid white;
        border-radius: 0;
      }
    }
  }
`;

const InputLoading = styled(InputLoadingBase)`
  position: absolute !important;
  top: calc(50% - 0.3125rem);
  right: 0.625rem;
`;

export { TextField };
