import { captureException } from '@sentry/node';
import {
  CONTRIBUTION_SESSION_ITEM,
  setLocalItem,
} from 'Client/utils/localstorage';
import { ProjectGaudi, ProjectProps } from 'Shared/types';
import { UserStatus } from 'Shared/types/user';
import { SupportedLanguages } from 'Client/constants/languages';
import { verifyEmailAssignedToContribution } from '../contributions';
import {
  assignUserToContribution,
  createUser,
  fetchUserByEmail,
} from '../user';

type Params = {
  emailValue: string; // at this point the emailValue is a valid email addresss, in lowercase
  language: SupportedLanguages; // the lang currently in the UI, used for creating a new user/demographics entry
  contributionId: string | undefined;
  project: ProjectGaudi & ProjectProps;
};

/**
 * Called when a user submits their email in the Contribution Flow.
 * TODO: move this complex method to a single API request, so that sensitive data can stay hidden
 * (and to avoid firing all those separate API requests).
 * @returns the user id found or created with this email and assigned to this contribution.
 * This is then added to a localStorage item.
 */
export const handleEmailSubmit = async ({
  emailValue,
  language,
  contributionId,
  project,
}: Params): Promise<string | void> => {
  if (contributionId) {
    // contribution id can be null if contr is not saved yet
    const emailIsAssignedToContr = await verifyEmailAssignedToContribution(
      emailValue,
      contributionId
    );
    // return if the email is already assigned to this contribution
    if (emailIsAssignedToContr) {
      return;
    }
  }
  const existingUser = await fetchUserByEmail(emailValue);
  if (!existingUser) {
    // if the user doesn't exist, create their db entry
    // and add their id and demographics id to the draft contribution (if saved already)
    try {
      const newUser = await createUser({
        email: emailValue,
        status: UserStatus.PENDING,
        language,
      });
      if (newUser) {
        if (contributionId) {
          await assignUserToContribution({
            user: newUser,
            contributionId,
            project,
            language,
          });
        }
        // if contribution is not saved yet but email is valid,
        // store the user id to localStorage.
        // This is to catch cases where user fills in all info at the last step
        setLocalItem(CONTRIBUTION_SESSION_ITEM, newUser._id);
        return newUser._id;
      }
    } catch (error) {
      captureException(
        `Error on handleEmailSubmit.ts, saving new user failed: ${error}`
      );
    }
  } else {
    // if user exists, add their id and demographics id to the draft contribution (if saved already)
    if (contributionId) {
      await assignUserToContribution({
        user: existingUser,
        contributionId,
        project,
        language,
      });
    }
    // and store the id to localStorage
    setLocalItem(CONTRIBUTION_SESSION_ITEM, existingUser._id);
    return existingUser._id;
  }
};
