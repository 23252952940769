import { Features as ProjectFeatures } from 'Shared/types';
import { AddSectionBlockTypes, mapPageAddTiles } from '../constants';

export const getMapAddTiles = (features: ProjectFeatures) => {
  const cf3 = features?.cf3 || false;
  const respondentFileUpload = features?.respondentFileUpload || false;

  return mapPageAddTiles.filter((tile) => {
    if (
      !respondentFileUpload &&
      tile.key === AddSectionBlockTypes.RESPONDENT_FILE_UPLOAD_QUESTION
    ) {
      return false;
    }

    if (!cf3 && tile.key === AddSectionBlockTypes.PAGE_BREAK) {
      return false;
    }

    return true;
  });
};
