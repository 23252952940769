import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { ValidationBorderStyle } from 'Client/pages/edit/components/Form/Form.styles';
import { FormValidationStatus } from 'Client/types';

export const Wrapper = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const AddShapeButtonsWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: ${theme.colors.white[700]};
    padding: 0.5rem 1rem;
    margin-top: -1.5rem;
  `}
`;

export const AddShapeButton = styled.button<{ isDrawing: boolean }>`
  ${({ theme, isDrawing }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    background: none;
    border: none;
    &:hover {
      cursor: pointer;
    }
    & > div {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.5rem;
      border-radius: 0.375rem;
      background: ${isDrawing
        ? theme.colorMappings.editModePurple
        : theme.colors.white[500]};
    }
  `}
`;
export const FeatureIconContainer = styled.div<{ color?: string }>`
  ${({ theme, color }) => css`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.375rem;
    background: ${color || lighten(0.63, theme.colorMappings.editModePurple)};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const FeaturesContainer = styled.div`
  width: 100%;
`;

export const FeatureItem = styled.div<{
  beingEdited: boolean;
  statusType?: FormValidationStatus['type'];
}>`
  ${({ theme, beingEdited }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid ${theme.colors.grey[300]};
    padding: 0.625rem 1rem;
    padding-left: 0.5rem;
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    ${beingEdited &&
    css`
      background: ${lighten(0.63, theme.colorMappings.editModePurple)};
      & > div > button {
        outline: 0.125rem solid
          ${lighten(0.3, theme.colorMappings.editModePurple)};
      }
    `}
  `}
  ${({ theme, statusType }) => ValidationBorderStyle({ theme, statusType })}
`;

export const FeatureLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 70%;
  & > svg {
    margin-right: calc(0.75rem - 0.5rem);
  }
  & > span {
    width: 65%;
    text-overflow: ellipsis;
    line-break: unset;
    white-space: nowrap;
    overflow: hidden;
  }
`;
export const FeatureRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  & > svg {
    cursor: pointer;
  }
`;

export const EditLayerButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    padding: 0.5rem 0.9375rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.1875rem;
    border: 0.0625rem solid ${theme.colorMappings.greyButtonBorder};
    background: ${theme.colors.white[500]};
  `}
`;
export const InvisibleInput = styled.input`
  display: none;
`;

export const DraggableHeader = styled.div`
  cursor: default;
`;

export const DraggableHeaderActive = styled.div`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
  padding: 0 0.5rem;
`;
