import * as React from 'react';
import styled, { css } from 'styled-components';
import { readableColor, darken } from 'polished/lib';
import MuiButton from '@material-ui/core/Button';
import { Props } from './types';

const MuiButtonWrapper = ({ ...props }: Props, ref) => (
  <MuiButton {...props} ref={ref} />
);

const Button = styled<React.FC<Props>>(
  React.forwardRef<HTMLButtonElement>(MuiButtonWrapper)
)`
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  color: ${({ theme }) =>
    readableColor(theme.colorMappings.primaryButtonBackground)};
  background-color: ${({ theme }) =>
    theme.colorMappings.primaryButtonBackground};
  border: solid 0.125rem
    ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  padding: 0.375rem 1rem;
  min-height: 3.125rem;
  border-radius: 0;
  & > span {
    display: flex;
    gap: 0.5rem;
  }
  &:hover {
    color: ${({ theme }) =>
      readableColor(darken(0.1, theme.colorMappings.primaryButtonBackground))};
    background-color: ${({ theme }) =>
      darken(0.1, theme.colorMappings.primaryButtonBackground)};
    border: solid 0.125rem
      ${({ theme }) => darken(0.1, theme.colorMappings.primaryButtonBackground)};
  }

  &:focus {
    &:after {
      content: '';
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      right: -0.5rem;
      bottom: -0.5rem;
      border: 0.125rem solid
        ${({ theme }) => theme.colorMappings.focusHighlight};
      z-index: 1;
    }
  }

  ${({ theme, borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};

      &:focus {
        &:after {
          content: '';
          position: absolute;
          border-radius: ${borderRadius};
          top: -0.5rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: -0.5rem;
          border: 0.125rem solid ${theme.colorMappings.focusHighlight};
          z-index: 1;
        }
      }
    `}

  ${({ theme, inverse }) =>
    inverse &&
    css`
      color: ${readableColor(theme.colors.white[500])};
      background-color: ${theme.colors.white[500]};
      border: solid 0.125rem ${theme.colorMappings.primaryButtonBackground};

      &:hover {
        color: ${readableColor(theme.colorMappings.primaryButtonBackground)};
        background-color: ${theme.colorMappings.primaryButtonBackground};
        border: solid 0.125rem ${theme.colorMappings.primaryButtonBackground};
      }
    `}

  ${({ theme, colorMapping }) =>
    colorMapping &&
    colorMapping === 'CommonplaceBrand' &&
    css`
      color: ${readableColor(theme.colorMappings.CommonplaceBrand)};
      background-color: ${theme.colorMappings.CommonplaceBrand};
      border: solid 0.125rem ${theme.colorMappings.CommonplaceBrand};

      &:hover {
        color: ${readableColor(
          darken(0.1, theme.colorMappings.CommonplaceBrand)
        )};
        background-color: ${darken(0.1, theme.colorMappings.CommonplaceBrand)};
        border: solid 0.125rem
          ${darken(0.1, theme.colorMappings.CommonplaceBrand)};
      }
    `}

    ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.colorMappings.disabledButtonLabel};
      background-color: ${theme.colorMappings.disabledButtonBackground};
      border: solid 0.125rem ${theme.colorMappings.disabledButtonBackground};

      &:hover,
      &:active,
      &:focus {
        color: ${theme.colorMappings.disabledButtonLabel};
        background-color: ${theme.colorMappings.disabledButtonBackground};
        border: solid 0.125rem ${theme.colorMappings.disabledButtonBackground};
      }
    `}

    ${({ theme, link, removeDefaultStyling }) =>
    link &&
    css`
      color: ${theme.colorMappings.hyperlink};
      padding: 0 0.3125rem;
      background-color: inherit;
      border: none;

      &:focus {
        color: ${theme.palette.common.black};
        outline: none;
        background-color: ${theme.colorMappings.focusHighlight};
        border-radius: 0;

        &:after {
          content: none;
        }
      }

      &:hover {
        color: ${darken(0.1, theme.colorMappings.hyperlink)};
        background-color: inherit;
        border: none;
      }

      ${removeDefaultStyling &&
      css`
        padding: 0;
        min-height: unset;
        font-size: unset;
        vertical-align: baseline;
      `}
    `}
`;

export { Button };
