import styled from 'styled-components';
import { Typography } from 'Atoms/Typography/Typography';
import { Modal as ModalBase } from './Modal';

export const EditModeModal = styled(ModalBase)`
  .MuiPaper-root {
    width: 40rem;
    height: 16rem;
  }

  .MuiButton-root {
    font-size: 1.25rem;
    min-height: 0;
    margin: 0 0.5rem;
  }
`;

export const ButtonsWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Description = styled(Typography)`
  font-size: 1rem;
  margin-top: 1rem;
`;
