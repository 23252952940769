/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { ProjectGaudi, ProjectProps } from 'Shared/types';
import { sharingReducer } from './sharingReducer';

export interface SharingContextProps {
  sharing: {
    open: boolean;
    context: string;
    slug: string;
    commentId: string;
    isMap: boolean;
    proposalId?: string;
    otherProjectParams: {
      useOtherProjectParams: boolean;
      project: ProjectProps & ProjectGaudi;
    };
    newsHeadline?: string;
  };
}
const DEFAULT_STATE = {
  sharing: {
    open: false,
    context: '',
    slug: '',
    commentId: undefined,
    isMap: false,
    otherProjectParams: {
      useOtherProjectParams: false,
      project: undefined,
    },
  } as SharingContextProps['sharing'],
  dispatch: (() => {}) as React.Dispatch<
    React.SetStateAction<
      { type: string } & Partial<SharingContextProps['sharing']>
    >
  >,
};

const SharingContext = React.createContext(DEFAULT_STATE);

const SharingContextProvider = ({ children, initialContextState = null }) => {
  const [state, dispatch] = React.useReducer(
    sharingReducer,
    initialContextState || DEFAULT_STATE
  );

  return (
    <SharingContext.Provider value={{ dispatch, sharing: state.sharing }}>
      {children}
    </SharingContext.Provider>
  );
};

export { SharingContext, SharingContextProvider };
