/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';

const EditModeLayoutContext = React.createContext({
  isEditModeLayout: false,
  setEditModeLayout: (() => {}) as React.Dispatch<
    React.SetStateAction<boolean>
  >,
});

const useEditModeLayout = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const { isEditModeLayout, setEditModeLayout } = React.useContext(
    EditModeLayoutContext
  );

  return [isEditModeLayout, setEditModeLayout];
};

const EditModeLayoutProvider = ({ children }) => {
  const [isEditModeLayout, setEditModeLayout] = React.useState(false);

  return (
    <EditModeLayoutContext.Provider
      value={{ isEditModeLayout, setEditModeLayout }}
    >
      {children}
    </EditModeLayoutContext.Provider>
  );
};

export { EditModeLayoutProvider, useEditModeLayout };
