import styled, { css } from 'styled-components';
import { SimpleSelect } from 'Molecules';
import { FormValidationStatus } from 'Client/types';
import { ValidationBorderStyle } from '../Form.styles';

export const StyledRoundedDropdown = styled(SimpleSelect)<{
  disabled?: boolean;
  statusType?: FormValidationStatus['type'];
}>`
  ${({ theme, disabled, statusType }) => css`
    text-align: left;
    margin: 0.5rem 0 1rem 0;
    font-size: 1rem;

    .react-select__menu-list {
      margin-bottom: -0.2rem;
    }

    .MuiTypography-root {
      color: ${theme.colorMappings.placeholder};
      font-weight: ${theme.fontWeights.normal};
    }

    .react-select__dropdown-indicator {
      color: ${theme.colors.grey[900]};
    }

    .react-select__control {
      &.react-select__control--menu-is-open {
        border-bottom: 0.0625rem solid ${theme.colorMappings.formFieldBorder};
      }
      &.react-select__control--is-focused {
        border-color: ${theme.colorMappings.formFieldBorder};
        outline: none;
        box-shadow: none;
        &:after {
          content: '';
          position: absolute;
          border-radius: 0;
          top: -0.5rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: -0.5rem;
          border-color: transparent;
          z-index: 1;
        }
      }
      border-radius: 0.125rem;
      border-color: ${theme.colorMappings.formFieldBorder};
    }

    .react-select__menu {
      margin-top: 0.1875rem;
    }

    margin: 0;
    ${disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      filter: opacity(0.5);
    `}

    .react-select__control {
      border-radius: 0.5rem;
      border-color: ${theme.colors.white[300]};
      color: ${theme.colors.black[500]};
      &::placeholder {
        color: ${theme.colorMappings.greyPlaceholderColor};
      }
      ${ValidationBorderStyle({
        theme,
        statusType,
      })}
    }
    .react-select__indicators {
      background-color: ${theme.colors.white[400]};
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 0.5rem 0.5rem 0;
      & > div:first-child {
        position: absolute;
        right: 3.125rem;
      }
    }
    .react-select__single-value > .MuiListItemText-root > span {
      color: ${theme.colors.black[500]} !important;
    }

    ${disabled
      ? css`
          pointer-events: none;
          cursor: not-allowed;
          filter: opacity(0.5);
        `
      : ``}
  `}
`;
