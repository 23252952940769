import { USER_ROLES } from 'Client/constants/roles';
import { SupportedLanguages } from 'Client/constants/languages';
import { TeamMember } from './teamMembers';

export enum UserStatus {
  CONFIRMED = 'confirmed',
  OK = 'ok',
  PENDING = 'pending',
  SURVEYED = 'surveyed',
  VERIFIED = 'VERIFIED',
}

export interface Role {
  _id?: string;
  permissions: Array<string>;
  name: USER_ROLES;
  displayName: string;
}

export interface User {
  _doc?: User;
  blocked?: Record<string, unknown>;
  _id: string;
  id?: string;
  email: string | null;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  projects?: {
    [projectName: string]: {
      role: USER_ROLES;
      organisation: string;
    };
  };
  userName?: string; // not used anymore
  provider?: string; // not used anymore
  providerId?: string; // not used anymore
  firstSeen?: Date; // not used anymore
  status: UserStatus;
  superRole?: string;
  password?: string;
  salt?: string;
  role?: Role;
  language?: SupportedLanguages;
  fingerprints?: Array<string>;
  deleted?: string;
  signedUpDate?: Date;
  survey?: boolean;
  teamMemberFromUser?: TeamMember;
}
