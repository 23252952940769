import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { readableColor } from 'polished';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/node';
import { useSelector } from 'react-redux';
import { useMutation, gql } from '@apollo/client';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { updateFeature as updateFeatureV4 } from 'Client/services/geolytix/v4';
import { BoldInfoIcon, CrosshairIcon, EditIcon } from 'Atoms/Icons';
import {
  FeatureDrawGuidance,
  Switch,
  ToolTip,
} from 'Client/components/molecules';
import { useMap, useProject } from 'Client/utils/hooks';
import { savePolygon } from 'Client/services/geolytix';
import {
  handleDrawPoint,
  handleDrawPolygon,
  handleDrawCircle,
  handleDrawLine,
  uploadShapeFile,
} from 'Client/services/map/draw';
import {
  DrawShapeTypes,
  DrawStateValues,
  EditingMapFeature,
} from 'Shared/types/map';
import { locateFeature } from 'Client/services/map/locateFeature';
import { RootState } from 'Client/redux-store';
import { getFeatures } from 'Client/services/map/features/getFeatures';
import { copyCustomLayerFeatures } from 'Client/services/map/draw/uploadShapeFile';
import {
  AddShapeButton,
  AddShapeButtonsWrapper,
  FeatureItem,
  FeaturesContainer,
  Wrapper,
  FeatureIconContainer,
  FeatureRightSide,
  FeatureLeftSide,
  EditLayerButton,
  InvisibleInput,
  DraggableHeaderActive,
  DraggableHeader,
} from './index.styles';
import {
  MapEditButtonInverted,
  Section,
  SectionTitle,
  StyledInfoContainer,
  StyledSwitch,
  SwitchField,
  InputTitle,
  TooltipMessage,
} from '../../MapEditorV2.styles';
import { FeatureEditor } from '../FeatureEditor';
import { LegendsEditor } from '../LegendsEditor';
import { FeatureIcon } from '../shared/FeatureIcon';
import { getDefaultFeatureProps } from '../shared/getDefaultFeatureProps';
import { ShapefileUploadModal } from './ShapefileUpload';

export const UPLOAD_SHAPEFILE = gql`
  mutation UploadShapefile($uploadShapefileInput: uploadShapefileInput) {
    uploadShapefile(uploadShapefileInput: $uploadShapefileInput)
  }
`;

export const COPY_CUSTOM_LAYER_FEATURES = gql`
  mutation CopyCustomLayerFeatures(
    $copyCustomLayerFeaturesInput: copyCustomLayerFeaturesInput
  ) {
    copyCustomLayerFeatures(
      copyCustomLayerFeaturesInput: $copyCustomLayerFeaturesInput
    )
  }
`;

export const Layers = ({ allFeatures, setAllFeatures }) => {
  const {
    dispatch,
    state: { proposal, features, xyz, editingFeature },
    version,
  } = useMap();
  const router = useRouter();
  const theme = useTheme();
  const project = useProject();
  const { errors } = useSelector((s: RootState) => s.editModeValidation);
  const { t } = useTranslation('customer');
  const { editModePurple } = theme['colorMappings'];

  const SupportSettings = React.useMemo(
    () => ({
      showLayersPanel: !!proposal?.featureFlags?.hideMapLayersTab,
    }),
    [proposal]
  );
  const [featureLayer, setFeatureLayer] = React.useState(null);
  const [editingLegends, setEditingLegends] = React.useState(false);
  const [isDrawing, setIsDrawing] = React.useState(null);
  const [supportSettings, setSupportSettings] = React.useState(SupportSettings);
  const [drawState, setDrawState] = React.useState<DrawStateValues>(
    DrawStateValues.DRAWING_STARTED
  );
  const inputFileRef = React.useRef(null);

  const workspaceData = proposal.geolytixWorkspace.locales.UK;

  const allFeatureNames = allFeatures
    .filter((f) => f.id !== editingFeature?.id)
    .map((f) => f?.properties?.metadata?.name)
    .filter((f) => Boolean(f));

  const initialLegends = workspaceData.legends.length
    ? workspaceData.legends
    : allFeatures.map((feature) => ({
        title: feature.properties.metadata.title,
        color: feature.properties.metadata.fillColor || editModePurple,
      }));

  React.useEffect(() => {
    setFeatureLayer(
      new global.ol.layer.Vector({
        map: xyz.map,
        source: new global.ol.source.Vector({
          projection: `EPSG:${xyz.layers.list.Custom.srid}`,
        }),
        style: xyz.mapview.layer.styleFunction(xyz.layers.list.Custom),
      })
    );
  }, [xyz]);
  const onDrawEnd = (data) => {
    const featureData = {
      ...data,
      id: 'new',
      properties: {
        metadata: getDefaultFeatureProps('custom_layers', proposal, xyz),
      },
    };
    dispatch({
      type: 'SET_EDITING_FEATURE',
      payload: { editingFeature: featureData },
    });
  };
  React.useEffect(() => {
    dispatch({
      type: 'SET_PROPOSAL',
      payload: {
        ...proposal,
        featureFlags: {
          ...proposal.featureFlags,
          hideMapLayersTab: supportSettings.showLayersPanel,
        },
      },
    });
  }, [supportSettings]);

  const [openUploadShapefileModal, setOpenUploadShapefileModal] =
    React.useState(false);

  const handleDraw = (type: DrawShapeTypes) => {
    setIsDrawing(type);
    switch (type) {
      case DrawShapeTypes.POINT:
        handleDrawPoint(xyz, proposal, project, featureLayer, onDrawEnd);
        break;
      case DrawShapeTypes.POLYGON:
        handleDrawPolygon(
          xyz,
          proposal,
          project,
          featureLayer,
          onDrawEnd,
          drawState,
          setDrawState
        );
        break;
      case DrawShapeTypes.CIRCLE:
        handleDrawCircle(xyz, proposal, project, featureLayer, onDrawEnd);
        break;
      case DrawShapeTypes.LINE:
        handleDrawLine(xyz, proposal, project, featureLayer, onDrawEnd);
        break;
      case DrawShapeTypes.SHAPEFILE:
        setOpenUploadShapefileModal(true);
        break;
      default:
        break;
    }
  };

  const handleFeatureSave = async () => {
    try {
      const _feature = { ...editingFeature };
      _feature.properties = {
        ..._feature.properties,
        metadata: {
          isPlanningApp: false,
          ..._feature.properties.metadata,
        },
      };

      /* Removing handle for custom 4258 */
      const layer = 'Custom'; // hoverablePopup ? 'Custom' : 'Custom 4258';
      const { geometry, properties } = _feature;

      const featureId = await savePolygon({
        geometry,
        properties,
        xyz,
        layer,
        pageId: proposal.pageId,
        project: project._id,
        language: router.locale || 'en-GB',
      });

      const newFeatures = { [layer]: [], ...features };
      newFeatures[layer].push({
        type: 'Features',
        geometry: _feature['geometry'],
        id: featureId,
        properties: { metadata: null },
      });
      newFeatures[layer].sort((a, b) => a.id - b.id);
      const sortedFeatures = { ...newFeatures };

      xyz.map.getInteractions().forEach((interaction) => {
        if (interaction.getListeners('drawend')) {
          interaction.setActive(false);
        }
      });

      featureLayer.getSource().clear();

      xyz.mapview.node.style.cursor = 'default';

      xyz.layers.list[editingFeature.layer].reload();

      dispatch({
        type: 'SET_FEATURES',
        payload: { features: sortedFeatures },
      });
      dispatch({
        type: 'SET_EDITING_FEATURE',
        payload: { editingFeature: null },
      });
      getFeatures({
        proposal,
        xyz,
        project,
        version,
        dispatch,
      });
      setIsDrawing(null);
    } catch (err) {
      console.error(err);
      captureException(err);
    }
  };

  const handleFeatureToggle = async (
    feature: EditingMapFeature,
    active: boolean
  ) => {
    const updatedFeatures = allFeatures.map((f: EditingMapFeature) => {
      if (f.id === feature.id) {
        return {
          ...f,
          properties: {
            ...f.properties,
            active,
          },
        };
      }
      return f;
    });
    setAllFeatures(updatedFeatures); // in order to update the UI instantly
    await updateFeatureV4({
      layer: feature.layer,
      xyz,
      table: feature.table,
      id: feature.id,
      body: { metadata: { ...feature.properties.metadata }, active },
    });
    xyz.layers.list[feature.layer].reload();
    getFeatures({
      proposal,
      xyz,
      project,
      version,
      dispatch,
    });
  };
  const [loadedShapeFile, setLoadedShapefile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleLoadedShapeFile = (e) => {
    const { files } = e.target;

    setLoadedShapefile(files);
  };

  const [uploadShapefileMutation] = useMutation(UPLOAD_SHAPEFILE);
  const [copyCustomLayersFeaturesMutation] = useMutation(
    COPY_CUSTOM_LAYER_FEATURES
  );

  const handleCopyCustomLayers = async (customer) => {
    try {
      await copyCustomLayerFeatures(
        customer,
        project._id,
        proposal.pageId,
        dispatch,
        copyCustomLayersFeaturesMutation
      );

      setLoading(false);
      setOpenUploadShapefileModal(false);
    } catch (error) {
      console.error('handleCopyCustomLayers error: ', error);
      captureException(error);
    }
  };

  const handleUploadShapefile = async () => {
    try {
      const layer = 'custom_layers';

      await uploadShapeFile(
        loadedShapeFile,
        project._id,
        proposal.pageId,
        dispatch,
        layer,
        uploadShapefileMutation
      );

      toast('Shapefile added successfully!', {
        type: 'success',
      });

      setLoading(false);
      setOpenUploadShapefileModal(false);
      setLoadedShapefile(false);
    } catch (error) {
      console.error('handleUploadShapefile error: ', error);
      toast('An error has occurred...', { type: 'error' });
      setLoading(false);
      setOpenUploadShapefileModal(false);
      setLoadedShapefile(false);
      captureException(error);
    }
  };

  const handleEditFeature = (feature) => {
    if (editingFeature?.id === feature.id) {
      dispatch({
        type: 'SET_EDITING_FEATURE',
        payload: { editingFeature: null },
      });
      return;
    }
    dispatch({
      type: 'SET_EDITING_FEATURE',
      payload: { editingFeature: { ...feature } },
    });
  };

  const shapeOptions = [
    { label: t('Point'), value: DrawShapeTypes.POINT },
    { label: t('Polygon'), value: DrawShapeTypes.POLYGON },
    { label: t('Circle'), value: DrawShapeTypes.CIRCLE },
    { label: t('Line'), value: DrawShapeTypes.LINE },
    { label: t('Shapefile'), value: DrawShapeTypes.SHAPEFILE },
  ];

  const reorder = (
    list: EditingMapFeature[],
    startIndex: number,
    endIndex: number
  ): EditingMapFeature[] => {
    if (startIndex === endIndex) {
      return list;
    }
    const result = Array.from(list);
    const [toUpdate] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, toUpdate);
    const newIndexes = result.map((f, i) => {
      f.properties.metadata.zIndex = result.length - i;
      return f;
    });
    setAllFeatures(newIndexes);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    reorder(allFeatures, result.source.index, result.destination.index);
  };

  const getTextByDrawState = (drawState) => {
    const helperText = {
      [DrawStateValues.DRAWING_STARTED]: t(
        'Draw an area by dropping a series of points.'
      ), // 0 points
      [DrawStateValues.HAS_LESS_THAN_THREE_POINTS]: t(
        'Create a shape by adding more than two points.'
      ), // 2- points
      [DrawStateValues.HAS_MORE_THAN_TWO_POINTS]: t(
        'Complete the area by connecting to a point.'
      ), // 2+ points
      [DrawStateValues.DRAWING_FINISHED]: t(
        'Click on "Confirm shape" to proceed.'
      ), // finished
    }[drawState];
    return helperText;
  };

  const cancelDrawing = () => {
    const interactions = xyz.map.getInteractions().getArray();
    const drawInteractions = interactions.filter(
      (i) => i instanceof global.ol.interaction.Draw
    );
    drawInteractions.forEach((i) => {
      xyz.map.removeInteraction(i);
    });
    featureLayer.getSource().clear();
    setIsDrawing(null);
  };

  return (
    <>
      <Wrapper>
        <Section>
          <SectionTitle>{t('TILE PREVIEW')}</SectionTitle>
          <MapEditButtonInverted
            onClick={() => setEditingLegends(!editingLegends)}
          >
            <h4>{t('Edit legend')}</h4>
            <ToolTip
              hoverableElement={<BoldInfoIcon />}
              startPositionHorizontalMutation={-130}
            >
              <TooltipMessage>
                {t('Edit the legend for your map.')}
              </TooltipMessage>
            </ToolTip>
          </MapEditButtonInverted>
        </Section>
        <Section>
          <SectionTitle>{t('SUPPORT SETTINGS')}</SectionTitle>
          <SwitchField>
            <InputTitle>
              <p>{t('Show layers tab in respondent side panel')}</p>
            </InputTitle>
            <Switch
              colorMapping="editModePurple"
              checked={!supportSettings.showLayersPanel}
              onChange={(e) => {
                setSupportSettings({
                  ...supportSettings,
                  showLayersPanel: !e.target.checked,
                });
              }}
            />
          </SwitchField>
        </Section>
        <Section>
          <SectionTitle>{t('ADD SHAPES')}</SectionTitle>
          <StyledInfoContainer>
            <p>{t('Add point / shape highlights to your map')}</p>
          </StyledInfoContainer>

          <AddShapeButtonsWrapper>
            {shapeOptions.map((option) => (
              <AddShapeButton
                key={option.value}
                isDrawing={isDrawing === option.value}
                onClick={() => handleDraw(option.value)}
              >
                <div>
                  <FeatureIcon
                    type={option.value}
                    props={{
                      color:
                        isDrawing === option.value
                          ? 'white'
                          : theme['colorMappings'].editModePurple,
                    }}
                  />
                </div>
                <span>{option.label}</span>
              </AddShapeButton>
            ))}
          </AddShapeButtonsWrapper>
          <InvisibleInput
            type="file"
            onChange={handleLoadedShapeFile}
            ref={inputFileRef}
          />
        </Section>
        <Section>
          <SectionTitle>{t('SHAPES')}</SectionTitle>
          {!allFeatures.length ? (
            <p>{t('No shapes added yet')}</p>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="feature">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    style={{ width: '100%' }}
                    ref={provided.innerRef}
                  >
                    <FeaturesContainer>
                      {allFeatures.map((feature, index) => (
                        <Draggable
                          key={feature.id}
                          draggableId={`${feature.id}`}
                          index={index}
                          isDragDisabled
                        >
                          {(provided, snapshot) => {
                            const Wrapper = snapshot.isDragging
                              ? DraggableHeaderActive
                              : DraggableHeader;

                            return (
                              <Wrapper
                                ref={provided.innerRef}
                                key={index}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <FeatureItem
                                  data-id={feature.id}
                                  data-table={feature.table}
                                  data-zIndex={
                                    feature.properties.metadata.zIndex
                                  }
                                  key={feature.id}
                                  beingEdited={
                                    editingFeature?.id === feature.id
                                  }
                                  statusType={
                                    errors.find((e) =>
                                      e.field.includes(
                                        `map-edit/layers/shapes/${feature.id}`
                                      )
                                    )?.type
                                  }
                                >
                                  <FeatureLeftSide>
                                    <StyledSwitch
                                      colorMapping="editModePurple"
                                      checked={
                                        feature.properties.active || false
                                      }
                                      onChange={(e) => {
                                        handleFeatureToggle(
                                          feature,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <FeatureIconContainer
                                      color={
                                        feature.geometry.type ===
                                        DrawShapeTypes.LINE
                                          ? feature.properties.metadata
                                              .strokeColor
                                          : feature.properties.metadata
                                              .fillColor
                                      }
                                    >
                                      {feature.properties.metadata.svg ? (
                                        <>
                                          <img
                                            src={
                                              feature.properties.metadata.svg
                                            }
                                            alt={
                                              feature.properties.metadata.name
                                            }
                                            style={{
                                              width: '0.9375rem',
                                              height: '0.9375rem',
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <FeatureIcon
                                          type={feature.geometry.type}
                                          props={{
                                            color: readableColor(
                                              feature.geometry.type ===
                                                DrawShapeTypes.LINE
                                                ? feature.properties.metadata
                                                    .strokeColor
                                                : feature.properties.metadata
                                                    .fillColor
                                            ),
                                            height: 15,
                                          }}
                                        />
                                      )}
                                    </FeatureIconContainer>
                                    <span>
                                      {feature.properties.metadata.name}
                                    </span>
                                  </FeatureLeftSide>
                                  <FeatureRightSide>
                                    <CrosshairIcon
                                      onClick={() =>
                                        locateFeature(
                                          feature,
                                          feature.table,
                                          xyz
                                        )
                                      }
                                    />
                                    <EditLayerButton
                                      onClick={() => handleEditFeature(feature)}
                                    >
                                      <EditIcon width={12} height={12} />{' '}
                                      <span>{t('Edit')}</span>
                                    </EditLayerButton>
                                  </FeatureRightSide>
                                </FeatureItem>
                              </Wrapper>
                            );
                          }}
                        </Draggable>
                      ))}
                    </FeaturesContainer>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Section>
      </Wrapper>
      {editingFeature && (
        <FeatureEditor
          handleSave={handleFeatureSave}
          reloadFeatures={() =>
            getFeatures({
              proposal,
              xyz,
              project,
              version,
              dispatch,
            })
          }
          allFeatureNames={allFeatureNames}
        />
      )}
      {editingLegends && (
        <LegendsEditor
          initialLegends={initialLegends}
          handleClose={() => setEditingLegends(false)}
        />
      )}
      {openUploadShapefileModal && (
        <ShapefileUploadModal
          inputFileRef={inputFileRef}
          handleUploadShapefile={handleUploadShapefile}
          setOpenUploadShapefileModal={setOpenUploadShapefileModal}
          loading={loading}
          setLoading={setLoading}
          loadedShapeFile={loadedShapeFile}
          setLoadedShapefile={setLoadedShapefile}
          handleCopyCustomLayers={handleCopyCustomLayers}
        />
      )}
      {isDrawing === DrawShapeTypes.POLYGON && (
        <FeatureDrawGuidance
          drawState={drawState}
          helperText={getTextByDrawState(drawState)}
          confirmShape={() => {}}
          onCancelClick={cancelDrawing}
          hideControlButtons
          position={{
            right: '1rem',
          }}
        />
      )}
    </>
  );
};
