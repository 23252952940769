import { getConfig } from 'Shared/utils/getConfig';
import { isLive, isStaging, isLocal } from './env';

const getApiUrl = () => {
  const localUrl = 'http://localhost:8080/';

  const {
    publicRuntimeConfig: {
      apiUrls: { prodUrl, testUrl, stagingUrl },
    },
  } = getConfig();

  switch (true) {
    case isLive: {
      return prodUrl;
    }
    case isStaging: {
      return stagingUrl;
    }
    case isLocal: {
      return localUrl || stagingUrl;
    }
    default: {
      return testUrl || localUrl;
    }
  }
};

export default getApiUrl;
