/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { Action } from '@reduxjs/toolkit';
import { MapModes, XyzState, XyzV4State } from 'Shared/types/map';
import { mapReducer } from './mapReducer';

const DEFAULT_STATE = {
  // TODO: type this
  xyz: null,
  welcomePanel: true,
  infoPanelOpen: true,
  activeInfoPanelTab: 0,
  mapEditMode: false,
  selectedContribution: null,
  selectedCustomLayer: null,
  activeLayers: null,
  contributionFlow: null,
  proposal: null,
  mode: 'map' as MapModes,
  highlightedContribution: null,
  highlightedCustomLayer: null,
  showFilters: true,
  proposals: [],
  userAgreements: [],
  isContributionFlowStarted: false,
  features: {},
  featureFlags: {},
  draftContributionLayer: null,
  userId: null,
  image3d: null,
  uniqueId: null,
  editingFeature: null,
  filterData: {
    queryFilters: [],
    filterValues: [],
  },
} as XyzV4State | XyzState;

const MapContext = React.createContext({
  state: DEFAULT_STATE,
  dispatch: (() => {}) as React.Dispatch<React.SetStateAction<Action>>,
});

const MapContextProvider = ({ children, initialContextState = null }) => {
  const [state, dispatch] = React.useReducer(
    mapReducer,
    initialContextState || DEFAULT_STATE
  );

  return (
    <MapContext.Provider value={{ dispatch, state }}>
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapContextProvider };
