import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { ProjectGaudi } from 'Shared/types';
import { User } from 'Shared/types/user';
import { PageFeatures } from 'Shared/types/page';
import {
  demographicsPageAddTiles,
  milestonesPageAddTiles,
  projectTeamPageAddTiles,
  preferencesPageAddTiles,
  proposalsAddTies,
} from '../constants';
import { getHubAddTiles } from './getHubAddTiles';
import { getProposalAddTiles } from './getProposalAddTiles';
import { getMapAddTiles } from './getMapAddTiles';
import { DraggableAddItem } from '../types';
import { TileProps } from '../tiles';
import { TileData } from '../tiles/types';

export const getAddTiles = (
  currentViewType: string,
  user: User | undefined,
  project: Partial<ProjectGaudi>,
  tiles?: TileProps[] | TileData[],
  pageFeatures?: PageFeatures
): Array<DraggableAddItem> => {
  console.log('getAddTiles params: ', {
    currentViewType,
    user,
    project,
    tiles,
    pageFeatures,
  });
  switch (currentViewType) {
    case EDITABLE_PAGE_TYPES.HUB: {
      const hubAddTiles = getHubAddTiles(project.features, tiles);
      return hubAddTiles;
    }
    case EDITABLE_PAGE_TYPES.MILESTONES:
      return milestonesPageAddTiles;
    case EDITABLE_PAGE_TYPES.PROPOSAL:
      return getProposalAddTiles(user, project.features, pageFeatures);
    case EDITABLE_PAGE_TYPES.MAP:
      return getMapAddTiles(project.features);
    case EDITABLE_PAGE_TYPES.TEAMS:
      return projectTeamPageAddTiles;
    case EDITABLE_PAGE_TYPES.PREFERENCES:
      return preferencesPageAddTiles;
    case EDITABLE_PAGE_TYPES.DEMOGRAPHICS:
      return demographicsPageAddTiles;
    case EDITABLE_PAGE_TYPES.PROPOSALS:
      return proposalsAddTies;
    default:
      return [];
  }
};
