import { gql } from '@apollo/client';
import getClient from 'Shared/utils/apollo-client';

export const GET_CONSENTS = gql`
  query Query(
    $email: String
    $project: String
    $enabled: Boolean
    $type: String
  ) {
    getConsents(
      email: $email
      project: $project
      enabled: $enabled
      type: $type
    )
  }
`;

export const getConsentsByEmailProject = async (
  userEmail: string,
  projectName: string,
  apiToken: string
) => {
  const client = getClient(apiToken);

  const {
    data: { getConsents: consents },
  } = await client.query({
    query: GET_CONSENTS,
    variables: {
      email: userEmail,
      project: projectName,
    },
  });
  return consents;
};
