import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Tick from '@material-ui/icons/Check';
import { CopyIcon } from 'Atoms/Icons';
import { useSharingContext } from 'Client/context/sharingHooks';
import { SHARING_ACTION_TYPES } from 'Client/context/sharingReducer';
import { SharingPlatform, SharingProps } from '../types';
import { contextCtaMessage } from '../constants';
import {
  CopyButton,
  Header,
  IconWrapper,
  MediaItem,
  MediaItemsWrapper,
  StyledModal,
} from './SharingModal.styles';

const SharingModal: React.FC<Partial<SharingProps>> = ({
  copied,
  onCopy,
  platforms,
  sharingMethods,
  trackSharedToEvent,
}: SharingProps) => {
  const [{ open, context }, dispatch] = useSharingContext();
  const { t } = useTranslation();

  const onClose = () => {
    dispatch({ type: SHARING_ACTION_TYPES.SET_OPEN, open: false });
    dispatch({
      type: SHARING_ACTION_TYPES.SET_OTHER_PROJECT,
      otherProjectParams: {
        useOtherProjectParams: false,
        project: undefined,
      },
    });
  };

  const copyLink =
    sharingMethods.copyLink[context] && sharingMethods.copyLink[context]();

  const handleMediaItemClick = (platform: SharingPlatform | 'link') => {
    if (sharingMethods[platform][context]) {
      trackSharedToEvent(platform);
    }
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      data-testid="SharingModal"
      aria-expanded={open}
    >
      <Header>
        <Typography variant="h2" gutterBottom>
          {t('Share')}
        </Typography>
        <Typography variant="body2">{contextCtaMessage(t)[context]}</Typography>
      </Header>
      <MediaItemsWrapper>
        {platforms.map((p) => {
          return (
            <MediaItem
              href={
                sharingMethods?.[p.key]?.[context] &&
                sharingMethods?.[p.key]?.[context]()
              }
              target="_blank"
              key={p.key}
              data-testid={`MediaItem-${p.key}`}
              onClick={() => handleMediaItemClick(p.key)}
              tabIndex={0}
            >
              <IconWrapper platform={p.key} inverse={p.key === 'email'}>
                {p.icon}
              </IconWrapper>
              <div>
                <Typography variant="body2">{p.name}</Typography>
              </div>
            </MediaItem>
          );
        })}
        <CopyButton
          onClick={onCopy(copyLink)}
          data-testid={'MediaItem-link'}
          tabIndex={0}
        >
          <IconWrapper platform="copyLink" inverse>
            {!copied && <CopyIcon width={20} height={20} />}
            {copied && <Tick />}
          </IconWrapper>
          <div>
            <Typography variant="body2">
              {copied ? t('Copied') : t('Copy link')}
            </Typography>
          </div>
        </CopyButton>
      </MediaItemsWrapper>
    </StyledModal>
  );
};

export { SharingModal };
