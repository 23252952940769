/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// TODO: type this
import * as React from 'react';
import { SharingContext, SharingContextProps } from './sharingContext';

const useSharingContext = (): [
  SharingContextProps['sharing'],
  React.Dispatch<
    React.SetStateAction<
      { type: string } & Partial<SharingContextProps['sharing']>
    >
  >,
] => {
  const { sharing, dispatch } = React.useContext(SharingContext);

  return [sharing, dispatch];
};

export { useSharingContext };
