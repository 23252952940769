import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Typography } from 'Atoms/Typography/Typography';

export const ZoomButtonsContainer = styled.div`
  .zoom-buttons {
    bottom: ${({ theme }) => `calc(${theme.proposals.footer.height} + 1.5rem)`};
  }
`;

export const CTAPanel = styled.div`
  position: absolute;
  bottom: ${({ theme }) => `calc(${theme.proposals.footer.height} + 1.5rem)`};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => css`
    right: 5.375rem;
    ${theme.breakpoints.down('sm')} {
      right: 1.875rem;
    }
  `};
`;

export const CTADescription = styled.div<{ changeColour?: boolean }>`
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
  background-color: ${({ theme, changeColour }) =>
    changeColour ? rgba(122, 57, 149, 0.7) : theme.colorMappings.white};
  color: ${({ theme, changeColour }) =>
    changeColour ? theme.colorMappings.white : theme.colorMappings.black};
  border-radius: 1.5rem;
  width: 12.5rem;
  padding: 0.875rem 1.125rem 1.625rem;
  bottom: -1.25rem;
  position: relative;
  text-align: center;
  backdrop-filter: blur(0.125rem);
`;

export const CTAHeader = styled(Typography)`
  display: inline-flex;
  align-items: flex-start;
  gap: 0.4rem;

  ${({ theme }) =>
    theme.direction === 'ltr'
      ? 'margin-left: 0.25rem;'
      : 'margin-right: 0.25rem;'}
`;
