/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { ProjectGaudi, ProjectProps, User } from 'Shared/types';

const initialState = {
  data: {
    user: null,
    project: null,
  },
  setData: (() => {}) as React.Dispatch<
    React.SetStateAction<{ user: User; project: ProjectGaudi | ProjectProps }>
  >,
};

export const AnalyticsContext = React.createContext(initialState);
const AnalyticsProvider = ({ user, project, children }) => {
  const [data, setData] = React.useState({ user, project });

  return (
    <AnalyticsContext.Provider value={{ data, setData }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsProvider };
