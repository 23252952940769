import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiTypography from '@material-ui/core/Typography';
import { TypographyProps } from './types';

export const Typography: React.FC<TypographyProps> = ({
  ...props
}: TypographyProps) => <BaseTypography {...props} />;

const BaseTypography = styled(({ ...rest }) => <MuiTypography {...rest} />)`
  ${({ theme, bold, semi, fontSize, italic, lineHeight }) => css`
    ${bold && `font-weight: ${theme.fontWeights.bold};`}
    ${semi && `font-weight: ${theme.fontWeights.semi};`}
    ${fontSize && `font-size: ${fontSize};`}
    ${italic && `font-style: italic;`}
    ${lineHeight && `line-height: ${lineHeight};`}
  `}
`;
