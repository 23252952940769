import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiFormControl from '@material-ui/core/FormControl';
import { FormControlProps } from '.';

const FormControl: React.FC<FormControlProps> = ({
  children,
  hideLabel,
  ...props
}: FormControlProps) => (
  <Component data-testid="FormControlId" hideLabel={hideLabel} {...props}>
    {children}
  </Component>
);

const Component = styled(({ ...props }: FormControlProps) => (
  <MuiFormControl {...props} />
))`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ hideLabel }) =>
    hideLabel &&
    css`
      .MuiFormControlLabel-label {
        visibility: hidden;
        width: 0;
      }
    `}
  ${({ isMap }) =>
    isMap &&
    css`
      width: 100%;
      margin-top: 1.5rem;
    `}
`;

export { FormControl };
