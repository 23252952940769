import * as React from 'react';
import { IconProps } from '../types';

export const ArrowIcon2: React.FC<IconProps> = ({
  color = 'white',
  width = 30,
  height = 30,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6279 4.32474C10.9691 3.98355 11.5223 3.98355 11.8634 4.32474L16.6288 9.09014C16.97 9.43132 16.97 9.98449 16.6288 10.3257L11.8634 15.0911C11.5223 15.4323 10.9691 15.4323 10.6279 15.0911C10.2867 14.7499 10.2867 14.1967 10.6279 13.8555L13.9019 10.5816L3.30334 10.5816C2.82084 10.5816 2.42969 10.1904 2.42969 9.7079C2.42969 9.22539 2.82084 8.83424 3.30334 8.83424L13.9019 8.83425L10.6279 5.56027C10.2867 5.21909 10.2867 4.66592 10.6279 4.32474Z"
      fill={color}
    />
  </svg>
);
