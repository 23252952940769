import * as React from 'react';
import styled, { css } from 'styled-components';
import { Scrollbar as Base } from '@commonplace/scrollbar';
import { editModeMobile } from 'Client/components/theme/utils';
import { LayoutScrollProps } from './types';

export const LayoutScroll: React.FC<LayoutScrollProps> = ({
  children,
  trackStyles,
  thumbStyles,
  scrollbarStyles,
}: LayoutScrollProps) => {
  return (
    <Scrollbar
      scrollbarStyles={scrollbarStyles}
      minimalThumbSize={75}
      disableTrackYWidthCompensation
      noScrollY
      trackXProps={{
        // eslint-disable-next-line react/display-name
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <Track
              trackStyles={trackStyles}
              rest={restProps}
              elementRef={elementRef}
              className="TrackX"
            />
          );
        },
      }}
      thumbXProps={{
        // eslint-disable-next-line react/display-name
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <Thumb
              thumbStyles={thumbStyles}
              rest={restProps}
              elementRef={elementRef}
              className="ThUmBX"
            />
          );
        },
      }}
    >
      {children}
    </Scrollbar>
  );
};

const Scrollbar = styled(({ scrollbarStyles, ...props }) => (
  <Base {...props} />
))`
  width: 100%;

  ${({ scrollbarStyles }) => scrollbarStyles}

  .ScrollbarsCustom-Content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
  }

  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.up('md'),
      css`
        .ScrollbarsCustom-Wrapper,
        .ScrollbarsCustom-Scroller {
          overflow: initial !important;
        }
      `
    )}

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        height: 22rem;

        .ScrollbarsCustom-Content {
          display: inline-flex;
          flex-wrap: nowrap;
          margin: 0 0.75rem 0 0.5rem;
        }
      `
    )}
  `}
`;

const Track = styled(({ trackStyles, elementRef, rest, ...props }) => (
  <div {...rest} {...props} ref={elementRef} /*tracktyles={trackStyles} */ />
))`
  ${({ trackStyles, theme }) =>
    trackStyles
      ? trackStyles
      : css`
          height: 0.25rem !important;
          width: 12rem !important;
          margin-left: calc(50% - 6rem) !important;
          background-color: ${theme.colors.grey[200]} !important;
        `}

  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.up('md'),
      css`
        display: none;
      `
    )}
  `}
`;

const Thumb = styled(({ thumbStyles, elementRef, rest, ...props }) => (
  <div {...rest} {...props} ref={elementRef} thumbStyles />
))`
  ${({ thumbStyles, theme }) =>
    thumbStyles
      ? thumbStyles
      : css`
          height: 0.25rem !important;
          background-color: ${theme.colorMappings.brand} !important;
        `}
`;

export default LayoutScroll;
