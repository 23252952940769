import React from 'react';
import { IconProps } from '../types';

export const CircleClose: React.FC<IconProps> = ({
  color = '#C82A1D',
  width = 20,
  height = 21,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9987 3.33211C6.08668 3.33211 2.91536 6.50343 2.91536 10.4154C2.91536 14.3275 6.08668 17.4988 9.9987 17.4988C13.9107 17.4988 17.082 14.3275 17.082 10.4154C17.082 6.50343 13.9107 3.33211 9.9987 3.33211ZM2.08203 10.4154C2.08203 6.04319 5.62644 2.49878 9.9987 2.49878C14.371 2.49878 17.9154 6.04319 17.9154 10.4154C17.9154 14.7877 14.371 18.3321 9.9987 18.3321C5.62644 18.3321 2.08203 14.7877 2.08203 10.4154ZM7.20412 7.62082C7.36684 7.4581 7.63066 7.4581 7.79338 7.62082L9.9987 9.82614L12.204 7.62082C12.3667 7.4581 12.6306 7.4581 12.7933 7.62082C12.956 7.78354 12.956 8.04736 12.7933 8.21007L10.588 10.4154L12.7934 12.6208C12.9561 12.7835 12.9561 13.0473 12.7934 13.2101C12.6307 13.3728 12.3668 13.3728 12.2041 13.2101L9.9987 11.0046L7.79328 13.2101C7.63056 13.3728 7.36674 13.3728 7.20402 13.2101C7.0413 13.0473 7.0413 12.7835 7.20402 12.6208L9.40944 10.4154L7.20412 8.21007C7.04141 8.04736 7.04141 7.78354 7.20412 7.62082Z"
        fill={color}
      />
    </svg>
  );
};
