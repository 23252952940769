import { cpBrandName, cpBrandNamePlural } from 'Client/constants/brand';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { PreferencesPageProps } from 'Client/pages/preferences';

type Params = {
  lang: string;
};

/** @deprecated on CF 3.0 */
export const getTemplatePreferencesPage = ({
  lang,
}: Params): PreferencesPageProps =>
  ({
    ar: {
      heading: 'ابق في الحلقة',
      description:
        'يلتزم {{customerName}} بإبقائك على اطلاع دائم بكيفية تطور خطط منطقتك.',
      defaultConsents: {
        title: 'ابق على اطلاع',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'ردود التعليق',
            description: `من حين لآخر قد يستجيب أعضاء الفريق للتعليقات. إذا ردوا على رسالتك ، فهل ترغب في أن يتم إعلامك عن طريق رسالة بريد إلكتروني مرسلة من خلال ${cpBrandName}؟`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'تحديثات المشروع',
            description: `أعضاء الفريق نشر الأخبار والتحديثات على هذا المشروع. عندما يفعلون ذلك ، هل ترغب في أن يتم إعلامك عن طريق بريد إلكتروني مُرسَل من خلال ${cpBrandName}؟`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `${cpBrandNamePlural} جديد في منطقتك`,
            description: `يعمل ${cpBrandName} مع العديد من المؤسسات لتشكيل أماكن رائعة. إذا كان هناك ${cpBrandName} جديد في منطقتك ، هل ترغب في تلقي بريد إلكتروني لإعلامك بذلك؟`,
          },
        ],
      },
      customConsents: {
        title: 'الاتصالات والموافقات الخاصة بالمشروع',
        description:
          'هذه الموافقات خاصة بهذا المشروع ، التي وضعها فريق المشروع.',
        helperText: 'حدد أي',
      },
    },
    bn: {
      heading: 'লুপ রাখতে',
      description:
        '{{customerName}} আপনার আশেপাশের জন্য পরিকল্পনাগুলি কীভাবে বিকশিত হচ্ছে সে সম্পর্কে আপনাকে আপডেট রাখতে প্রতিশ্রুতিবদ্ধ।',
      defaultConsents: {
        title: 'জেনে রাখুন',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'মন্তব্য উত্তর',
            description: `মাঝে মাঝে দলের সদস্যরা মন্তব্যের জবাব দিতে পারে। যদি তারা আপনার উত্তর দেয়, তাহলে আপনি কি ${cpBrandName} এর মাধ্যমে পাঠানো একটি ইমেলের মাধ্যমে বিজ্ঞপ্তি পেতে চান?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'প্রকল্প আপডেট',
            description: `দলের সদস্যরা এই প্রকল্পের খবর এবং আপডেট পোস্ট করে। যখন তারা করবে, আপনি কি ${cpBrandName} এর মাধ্যমে পাঠানো একটি ইমেলের মাধ্যমে বিজ্ঞপ্তি পেতে চান?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `আপনার এলাকায় নতুন ${cpBrandNamePlural}`,
            description: `${cpBrandName} অনেক প্রতিষ্ঠানের সাথে কাজ করে দারুণ জায়গা তৈরি করতে। যদি আপনার এলাকায় একটি নতুন ${cpBrandName} থাকে, তাহলে আপনি কি আপনাকে জানানোর জন্য একটি ইমেল পেতে চান`,
          },
        ],
      },
      customConsents: {
        title: 'প্রকল্প নির্দিষ্ট যোগাযোগ এবং সম্মতি',
        description:
          'এই সম্মতিগুলি এই প্রকল্পের জন্য নির্দিষ্ট, প্রকল্প দল দ্বারা সেট করা হয়েছে৷',
        helperText: 'যেকোনো একটি নির্বাচন করুন',
      },
    },
    'cy-GB': {
      heading: 'Cadwch yn y ddolen',
      description:
        "Mae {{customerName}} yn ymrwymo i roi'r wybodaeth ddiweddaraf i chi am sut mae cynlluniau ar gyfer eich cymdogaeth yn esblygu.",
      defaultConsents: {
        title: 'Arhoswch yn y gwybod',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Ymatebion sylwadau',
            description: `Yn achlysurol gall aelodau tîm ymateb i sylwadau. Os ydyn nhw'n ymateb i'ch un chi, a fyddech chi'n hoffi cael gwybod trwy e-bost a anfonwyd trwy ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Diweddariadau prosiect',
            description: `Mae aelodau'r tîm yn postio newyddion a diweddariadau ar y prosiect hwn. Pan fyddant yn gwneud hynny, a fyddech chi'n hoffi cael gwybod trwy e-bost a anfonwyd trwy ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `${cpBrandName} newydd yn eich ardal chi`,
            description: `Mae ${cpBrandName} yn gweithio gyda llawer o sefydliadau i lunio lleoedd gwych. Os oes ${cpBrandName} newydd yn eich ardal chi, a hoffech chi dderbyn e-bost i roi gwybod i chi?`,
          },
        ],
      },
      customConsents: {
        title: 'Cyfathrebiadau a chaniatâd penodol i brosiect',
        description:
          "Mae'r caniatadau hyn yn benodol ar gyfer y prosiect hwn, a bennir gan dîm y prosiect.",
        helperText: 'Dewiswch unrhyw',
      },
    },
    de: {
      heading: 'Bleiben Sie auf dem Laufenden',
      description:
        '{{customerName}} verpflichtet sich, Sie über die Entwicklung der Pläne für Ihre Nachbarschaft auf dem Laufenden zu halten.',
      defaultConsents: {
        title: 'Bleiben Sie auf dem Laufenden',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Antworten kommentieren',
            description: `Gelegentlich können Teammitglieder auf Kommentare antworten. Wenn sie auf Ihre Antwort antworten, möchten Sie per E-Mail benachrichtigt werden, die über ${cpBrandName} gesendet wird?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Projektaktualisierungen',
            description: `Teammitglieder veröffentlichen Neuigkeiten und Updates zu diesem Projekt. Möchten Sie in diesem Fall per E-Mail benachrichtigt werden, die über ${cpBrandName} gesendet wird?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `Neue ${cpBrandNamePlural} in Ihrer Nähe`,
            description: `${cpBrandName} arbeitet mit vielen Organisationen zusammen, um großartige Orte zu gestalten. Wenn es einen neuen ${cpBrandName} in Ihrer Nähe gibt, möchten Sie eine E-Mail erhalten, um Sie darüber zu informieren?`,
          },
        ],
      },
      customConsents: {
        title: 'Projektspezifische Mitteilungen und Einwilligungen',
        helperText: 'Wählen Sie eine aus',
        description:
          'Diese Zustimmungen gelten spezifisch für dieses Projekt und werden vom Projektteam festgelegt.',
      },
    },
    'en-GB': {
      heading: 'Keep in the loop',
      description:
        '{{customerName}} commits to keep you updated on how plans for your neighbourhood are evolving.',
      defaultConsents: {
        title: 'Stay in the know',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Comment replies',
            description: `Occasionally team members may respond to comments. If they respond to yours, would you like to be notified by an email sent through ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Project updates',
            description: `Team members post news and updates on this project. When they do, would you like to be notified by an email sent through ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `New ${cpBrandNamePlural} in your area`,
            description: `${cpBrandName} works with a lot of organisations to shape great places. If there is a new ${cpBrandName} in your area, would you like to receive an email to let you know?`,
          },
        ],
      },
      customConsents: {
        title: 'Project specific communications and consents',
        description:
          'These consents are specific for this project, set by the project team.',
        helperText: 'Select any',
      },
    },
    es: {
      heading: 'Manténgase informado',
      description:
        '{{customerName}} se compromete a mantenerlo actualizado sobre cómo evolucionan los planes para su vecindario.',
      defaultConsents: {
        title: 'Mantente informado',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Comentarios respuestas',
            description: `Ocasionalmente, los miembros del equipo pueden responder a los comentarios. Si responden a la suya, ¿le gustaría recibir una notificación mediante un correo electrónico enviado a través de ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Actualizaciones de proyectos',
            description: `Los miembros del equipo publican noticias y actualizaciones sobre este proyecto. Cuando lo hagan, ¿le gustaría recibir una notificación por correo electrónico a través de ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `Nuevos ${cpBrandNamePlural} en tu área`,
            description: `${cpBrandName} trabaja con muchas organizaciones para dar forma a grandes lugares. Si hay un nuevo ${cpBrandName} en su área, ¿le gustaría recibir un correo electrónico para informarle?`,
          },
        ],
      },
      customConsents: {
        title: 'Comunicaciones y consentimientos específicos del proyecto',
        helperText: 'Seleccione cualquiera',
        description:
          'Estos consentimientos son específicos para este proyecto, establecidos por el equipo del proyecto.',
      },
    },
    gu: {
      heading: 'લૂપમાં રાખો',
      description:
        '{{customerName}} તમારા પડોશની યોજનાઓ કેવી રીતે વિકસિત થઈ રહી છે તેના વિશે તમને અપડેટ રાખવા માટે પ્રતિબદ્ધ છે.',
      defaultConsents: {
        title: 'માહિતગાર રહો',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'ટિપ્પણી જવાબો',
            description:
              'પ્રસંગોપાત ટીમના સભ્યો ટિપ્પણીઓનો જવાબ આપી શકે છે. જો તેઓ તમારો જવાબ આપે, તો શું તમે કોમનપ્લેસ દ્વારા મોકલેલા ઈમેલ દ્વારા સૂચિત થવા ઈચ્છો છો?',
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'પ્રોજેક્ટ અપડેટ્સ',
            description:
              'ટીમના સભ્યો આ પ્રોજેક્ટ પર સમાચાર અને અપડેટ્સ પોસ્ટ કરે છે. જ્યારે તેઓ કરે છે, ત્યારે શું તમે કોમનપ્લેસ દ્વારા મોકલેલ ઈમેલ દ્વારા સૂચિત થવા ઈચ્છો છો?',
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `તમારા વિસ્તારમાં નવા ${cpBrandNamePlural}`,
            description: `${cpBrandName} મહાન સ્થાનોને આકાર આપવા માટે ઘણી સંસ્થાઓ સાથે કામ કરે છે. જો તમારા વિસ્તારમાં નવું ${cpBrandName} સ્થાન છે, તો શું તમે તમને જણાવવા માટે એક ઈમેલ પ્રાપ્ત કરવા માંગો છો?`,
          },
        ],
      },
      customConsents: {
        title: 'પ્રોજેક્ટ ચોક્કસ સંચાર અને સંમતિ',
        description:
          'આ સંમતિઓ આ પ્રોજેક્ટ માટે વિશિષ્ટ છે, જે પ્રોજેક્ટ ટીમ દ્વારા સેટ કરવામાં આવી છે.',
        helperText: 'કોઈપણ પસંદ કરો',
      },
    },
    he: {
      heading: 'שמור על קשר',
      description:
        '{{customerName}} מתחייבת לעדכן אותך כיצד התוכניות עבור השכונה שלך מתפתחות.',
      defaultConsents: {
        title: 'הישאר בידע',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'תגובה תשובות',
            description: `מדי פעם חברי צוות עשויים להגיב להערות. אם הם מגיבים לשלך, האם תרצה לקבל הודעה באימייל שנשלח דרך ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'עדכוני פרויקט',
            description: `חברי הצוות מפרסמים חדשות ועדכונים על פרויקט זה. כשהם עושים זאת, האם תרצה לקבל הודעה באימייל שנשלח דרך ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `${cpBrandNamePlural} חדש באזור שלך`,
            description: `${cpBrandName} עובד עם הרבה ארגונים כדי לעצב מקומות נהדרים. אם יש ${cpBrandName} חדש באזור שלך, האם תרצה לקבל אימייל כדי ליידע אותך?`,
          },
        ],
      },
      customConsents: {
        title: 'תקשורת והסכמות ספציפיות לפרויקט',
        description:
          'הסכמות אלו הן ספציפיות לפרויקט זה, שנקבעו על ידי צוות הפרויקט.',
        helperText: 'בחר כל אחד',
      },
    },
    hi: {
      heading: 'पाश में रखें',
      description:
        '{{customerName}}आपके पड़ोस के लिए योजनाएं कैसे विकसित हो रही हैं, इसके बारे में आपको अपडेट रखने के लिए प्रतिबद्ध है।',
      defaultConsents: {
        title: 'जानकारी रखें',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'टिप्पणी उत्तर',
            description: `कभी-कभी टीम के सदस्य टिप्पणियों का जवाब दे सकते हैं। यदि वे आपका उत्तर देते हैं, तो क्या आप चाहेंगे कि आपको ईमेल द्वारा सूचित किया जाए ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'परियोजना अद्यतन',
            description: `टीम के सदस्य इस परियोजना पर समाचार और अपडेट पोस्ट करते हैं। जब वे ऐसा करते हैं, तो क्या आप चाहेंगे कि आपको ईमेल द्वारा सूचित किया जाए${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `नया${cpBrandNamePlural} आपके क्षेत्र में`,
            description: `${cpBrandName}महान स्थानों को आकार देने के लिए कई संगठनों के साथ काम करता है। अगर कोई नया है${cpBrandName} आपके क्षेत्र में, क्या आप आपको सूचित करने के लिए एक ईमेल प्राप्त करना चाहेंगे?`,
          },
        ],
      },
      customConsents: {
        title: 'परियोजना विशिष्ट संचार और सहमति',
        description:
          'ये सहमतिएँ इस परियोजना के लिए विशिष्ट हैं, जो परियोजना टीम द्वारा निर्धारित की गई हैं।',
        helperText: 'कोई भी चुनें',
      },
    },
    it: {
      heading: 'Tieniti aggiornato',
      description:
        "{{customerName}} si impegna a tenerti aggiornato sull'evoluzione dei piani per il tuo quartiere.",
      defaultConsents: {
        title: 'Rimani informato',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Risposte ai commenti',
            description: `Occasionalmente i membri del team possono rispondere ai commenti. Se rispondono alla tua, vorresti essere avvisato tramite un'e-mail inviata tramite ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Aggiornamenti del progetto',
            description: `I membri del team pubblicano notizie e aggiornamenti su questo progetto. Quando lo fanno, vorresti essere avvisato tramite un'e-mail inviata tramite ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `Nuovi ${cpBrandNamePlural} nella tua zona`,
            description: `${cpBrandName} collabora con molte organizzazioni per dare forma a luoghi fantastici. Se c'è un nuovo ${cpBrandName} nella tua zona, vorresti ricevere un'e-mail per informarti?`,
          },
        ],
      },
      customConsents: {
        title: 'Comunicazioni e consensi specifici del progetto',
        helperText: 'Seleziona qualsiasi',
        description:
          'Questi consensi sono specifici per questo progetto, stabiliti dal team di progetto.',
      },
    },
    ko: {
      heading: '루프를 유지하십시오',
      description:
        '{{customerName}} 님께서는 여러분의 이웃을 위한 계획이 어떻게 발전하고 있는지 계속 알려드리기 위해 최선을 다하고 있습니다.',
      defaultConsents: {
        title: '최신 정보 유지',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: '댓글 답글',
            description: `때때로 팀 구성원이 의견에 응답할 수 있습니다. 그들이 귀하의 응답에 응답하면 ${cpBrandName}을 통해 보낸 이메일로 알림을 받으시겠습니까?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: '프로젝트 업데이트',
            description: `팀원들은 이 프로젝트에 대한 뉴스와 업데이트를 게시합니다. 그럴 때 ${cpBrandName}을 통해 보낸 이메일로 알림을 받으시겠습니까?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `해당 지역의 새로운 ${cpBrandNamePlural}`,
            description: `${cpBrandName}은 훌륭한 장소를 만들기 위해 많은 조직과 협력합니다. 해당 지역에 새로운 ${cpBrandName}이 있는 경우 이를 알리는 이메일을 받으시겠습니까?`,
          },
        ],
      },
      customConsents: {
        title: '프로젝트별 커뮤니케이션 및 동의',
        description:
          '이러한 동의는 프로젝트 팀에서 설정한 이 프로젝트에만 적용됩니다.',
        helperText: '아무거나 선택하십시오.',
      },
    },
    ne: {
      heading: 'लुपमा राख्नुहोस्',
      description:
        '{{customerName}}तपाईंको छिमेकका योजनाहरू कसरी विकसित भइरहेका छन् भन्ने बारे तपाईंलाई अद्यावधिक राख्न प्रतिबद्ध छ।',
      defaultConsents: {
        title: 'जानकारीमा रहनुहोस्',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'कमेन्ट रिप्लाई',
            description: `कहिलेकाहीँ टोली सदस्यहरूले टिप्पणीहरूको जवाफ दिन सक्छन्। यदि तिनीहरूले तपाइँको जवाफ दिनुभयो भने, तपाइँ मार्फत पठाइएको इमेल द्वारा सूचित गर्न चाहनुहुन्छ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'परियोजना अद्यावधिकहरू',
            description: `टोली सदस्यहरूले यस परियोजनामा समाचार र अद्यावधिकहरू पोस्ट गर्छन्। जब तिनीहरूले गर्छन्, के तपाइँ इमेल मार्फत पठाइएको द्वारा सूचित गर्न चाहनुहुन्छ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `नयाँ${cpBrandNamePlural} आफ्नो क्षेत्रमा`,
            description: `${cpBrandName}उत्कृष्ट स्थानहरू आकार दिन धेरै संस्थाहरूसँग काम गर्दछ। यदि त्यहाँ नयाँ छ${cpBrandName} तपाईको क्षेत्रमा, तपाईलाई थाहा दिनको लागि इमेल प्राप्त गर्न चाहनुहुन्छ?`,
          },
        ],
      },
      customConsents: {
        title: 'परियोजना विशिष्ट संचार र सहमतिहरू',
        description:
          'यी सहमतिहरू यस परियोजनाका लागि विशिष्ट छन्, परियोजना टोलीद्वारा सेट गरिएको।',
        helperText: 'कुनै पनि चयन गर्नुहोस्',
      },
    },
    pnb: {
      heading: 'لوپ وچ رہو',
      description: `پروجیکٹ ٹیماں جہڑیاں ${cpBrandName} تے اپنڑی مصروفیات چلاندیاں نے اوہ تہانوں اپ ڈیٹس فراہم کرن دا عہد کردیاں نے تاکہ تہانوں ایہہ معلوم ہو سکے کہ تہاڈے پڑوس دے منصوبے کس طرح تیار ہو رہے نے۔`,
      defaultConsents: {
        title: 'باخبر رہو',
        helperText: 'کسے دا وی چناؤ کرو',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'تبصریاں دے جواب',
            description: `کدی کدی ٹیم دے ارکان تبصریاں دا جواب دے سکدے نے۔ اگر او تہاڈے تبصرے دا جواب دینڑ، کیا تسی ${cpBrandName} ولوں بھیجی گئی ای میل دے ذریعے آگاہ ہونڑا پسند کرو گے؟`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'پروجیکٹ دیاں اپ ڈیٹ',
            description: `ایس پروجیکٹ تے ٹیم دے ارکان خبراں تے اپ ڈیٹس پوسٹ کردے نے۔ جدوں او ایسا کرن، کیا تسی ${cpBrandName} ولوں بھیجی گئی ای میل دے ذریعے آگاہ ہونڑا پسند کرو گے؟`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `تہاڈے علاقے وچ نویں ${cpBrandNamePlural}`,
            description: `عظیم جگہاں دی تشکیل لئی ${cpBrandName} بہت ساریاں تنظیماں نال کم کردا اے۔ اگر تہاڈے علاقے وچ کوئی نویں ${cpBrandName} ہوے تے، کیا تہاڈی جانڑکاری لئی تسی ای میل وصول کرنا چاہو گے؟`,
          },
        ],
      },
      customConsents: {
        title: 'پروجیکٹ دیاں مخصوص اجازتاں',
      },
    },
    pl: {
      heading: 'Bądź na bieżąco',
      description:
        '{{customerName}} zobowiązuje się informować Cię na bieżąco o rozwoju planów w Twojej okolicy.',
      defaultConsents: {
        title: 'Bądź na bieżąco',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Odpowiedzi na komentarze',
            description: `Czasami członkowie zespołu mogą odpowiadać na komentarze. Jeśli odpowie na Twoją odpowiedź, czy chcesz otrzymać powiadomienie e-mailem wysłanym przez ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Aktualizacje projektu',
            description: `Członkowie zespołu publikują wiadomości i aktualizacje dotyczące tego projektu. Jeśli tak się stanie, czy chcesz otrzymać powiadomienie e-mailem wysłanym przez ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `Nowy ${cpBrandNamePlural} w Twojej okolicy`,
            description: `${cpBrandName} współpracuje z wieloma organizacjami, aby kształtować wspaniałe miejsca. Jeśli w Twojej okolicy pojawi się nowa ${cpBrandName}, czy chcesz otrzymać wiadomość e-mail, aby Cię o tym powiadomić?`,
          },
        ],
      },
      customConsents: {
        title: 'Komunikacja i zgody dotyczące konkretnego projektu',
        description:
          'Zgody te są specyficzne dla tego projektu, ustalane przez zespół projektowy.',
        helperText: 'Wybierz dowolny',
      },
    },
    pt: {
      heading: 'Mantenha-se informado',
      description:
        '{{customerName}} compromete-se a mantê-lo atualizado sobre como os planos para o seu bairro estão evoluindo.',
      defaultConsents: {
        title: 'Fique por dentro',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Respostas de comentários',
            description: `Ocasionalmente, os membros da equipe podem responder aos comentários. Se eles responderem ao seu, você gostaria de ser notificado por um e-mail enviado através de ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Atualizações do projeto',
            description: `Os membros da equipe postam notícias e atualizações sobre este projeto. Quando o fizerem, você gostaria de ser notificado por um e-mail enviado através de ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `Novos ${cpBrandNamePlural} na sua área`,
            description: `${cpBrandName} trabalha com muitas organizações para moldar ótimos lugares. Se houver um novo ${cpBrandName} em sua área, você gostaria de receber um e-mail para informá-lo?`,
          },
        ],
      },
      customConsents: {
        title: 'Comunicações e consentimentos específicos do projeto',
        description:
          'Esses consentimentos são específicos para este projeto, definidos pela equipe do projeto.',
        helperText: 'Selecione qualquer',
      },
    },
    ro: {
      heading: 'Păstrați în buclă',
      description:
        '{{customerName}} se angajează să vă țină la curent cu modul în care evoluează planurile pentru cartierul dvs.',
      defaultConsents: {
        title: 'Rămâi la curent',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Răspunsuri la comentarii',
            description: `Ocazional, membrii echipei pot răspunde la comentarii. În cazul în care răspunde la a ta, doriți să fie notificat printr-un e-mail trimis prin ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Actualizări de proiect',
            description: `Membrii echipei postează știri și actualizări despre acest proiect. Când o fac, doriți să fiți notificat printr-un e-mail trimis prin ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `Nou ${cpBrandNamePlural} în zona dvs.`,
            description: `${cpBrandName} lucrează cu o mulțime de organizații pentru a modela locuri minunate. Dacă există un nou ${cpBrandName} în zona dvs., doriți să primiți un e-mail pentru a vă anunța?`,
          },
        ],
      },
      customConsents: {
        title: 'Comunicări și aprobări specifice proiectului',
        description:
          'Aceste consimtamanturi sunt specifice acestui proiect, stabilite de echipa de proiect.',
        helperText: 'Selectați orice',
      },
    },
    so: {
      heading: 'Ku hay wareegga',
      description:
        '{{customerName}} waxa uu ballan qaadayaa in uu kula socodsiiyo sida qorshayaasha xaafaddaadu u horumarayaan.',
      defaultConsents: {
        title: 'La soco aqoonta',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Jawaabaha faallooyinka',
            description: `Mararka qaarkood xubnaha kooxda ayaa ka jawaabi kara faallooyinka. Haddii ay kaaga jawaabaan, ma jeclaan lahayd in lagugu soo ogeysiiyo iimaylka lagu soo diro ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Cusboonaysiinta mashruuca',
            description: `Xubnaha kooxda ayaa soo dhejiya wararka iyo wararka ku saabsan mashruucan. Markay sameeyaan, ma jeclaan lahayd in lagugu ogeysiiyo iimaylka lagu soo diray ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `${cpBrandNamePlural} cusub oo aaggaaga ah`,
            description: `${cpBrandName} waxay la shaqaysaa ururo badan si ay u qaabeeyaan meelo fiican. Haddii uu jiro ${cpBrandName} cusub aaggaaga, ma jeclaan lahayd in laguu soo diro iimayl si laguugu ogeysiiyo?`,
          },
        ],
      },
      customConsents: {
        title: 'Mashruuc xidhiidh gaar ah iyo ogolaansho',
        description:
          'Ogolaanshahan ayaa u gaar ah mashruucan, oo ay dejiyeen kooxda mashruuca.',
        helperText: 'Dooro mid kasta',
      },
    },
    ta: {
      heading: 'சுழற்சியில் வைக்கவும்',
      description:
        '{{customerName}} உங்கள் அக்கம்பக்கத்துக்கான திட்டங்கள் எவ்வாறு உருவாகின்றன என்பதைப் பற்றி உங்களுக்குத் தெரிவிக்க உறுதியளிக்கிறது.',
      defaultConsents: {
        title: 'தெரிந்துகொள்ளுங்கள்',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'கருத்து பதில்கள்',
            description:
              'எப்போதாவது குழு உறுப்பினர்கள் கருத்துகளுக்கு பதிலளிக்கலாம். அவர்கள் உங்களுக்குப் பதிலளித்தால், காமன்ப்ளேஸ் மூலம் அனுப்பப்படும் மின்னஞ்சல் மூலம் உங்களுக்குத் தெரிவிக்க விரும்புகிறீர்களா?',
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'திட்ட மேம்படுத்தல்கள்',
            description:
              'குழு உறுப்பினர்கள் இந்தத் திட்டம் குறித்த செய்திகளையும் புதுப்பிப்புகளையும் இடுகிறார்கள். அவர்கள் அவ்வாறு செய்யும்போது, காமன்ப்ளேஸ் மூலம் அனுப்பப்பட்ட மின்னஞ்சலில் உங்களுக்குத் தெரிவிக்க விரும்புகிறீர்களா?',
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `உங்கள் பகுதியில் உள்ள புதிய ${cpBrandName}`,
            description:
              'சிறந்த இடங்களை வடிவமைக்க காமன்ப்ளேஸ் பல நிறுவனங்களுடன் இணைந்து செயல்படுகிறது. உங்கள் பகுதியில் புதிய பொது இடம் இருந்தால், உங்களுக்குத் தெரிவிக்க மின்னஞ்சலைப் பெற விரும்புகிறீர்களா?',
          },
        ],
      },
      customConsents: {
        title: 'திட்ட குறிப்பிட்ட தகவல்தொடர்புகள் மற்றும் ஒப்புதல்கள்',
        description:
          'இந்த ஒப்புதல்கள் திட்டக் குழுவால் அமைக்கப்பட்ட இந்தத் திட்டத்திற்கான குறிப்பிட்டவை.',
        helperText: 'ஏதேனும் ஒன்றைத் தேர்ந்தெடுக்கவும்',
      },
    },
    tr: {
      heading: 'Gelişmelerden haberdar olun',
      description:
        '{{customerName}}, mahalleniz için planların nasıl geliştiği konusunda sizi güncel tutmayı taahhüt eder.',
      defaultConsents: {
        title: 'Gelişmelerden haberdar olun',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Yorum yanıtları',
            description: `Bazen ekip üyeleri yorumlara yanıt verebilir. Sizinkine yanıt verirlerse, ${cpBrandName} aracılığıyla gönderilen bir e-posta ile bilgilendirilmek ister misiniz?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Proje güncellemeleri',
            description: `Ekip üyeleri bu projeyle ilgili haberleri ve güncellemeleri yayınlar. Bunu yaptıklarında, ${cpBrandName} aracılığıyla gönderilen bir e-posta ile bilgilendirilmek ister misiniz?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `Bölgenizde yeni ${cpBrandNamePlural}`,
            description: `${cpBrandName} harika yerleri şekillendirmek için birçok kuruluşla birlikte çalışır. Bölgenizde yeni bir ${cpBrandName} varsa, sizi bilgilendirecek bir e-posta almak ister misiniz?`,
          },
        ],
      },
      customConsents: {
        title: 'Projeye özel iletişimler ve izinler',
        description:
          'Bu izinler, proje ekibi tarafından ayarlanan bu projeye özgüdür.',
        helperText: 'Herhangi birini seçin',
      },
    },
    ur: {
      heading: 'لوپ میں رکھیں',
      description: `پراجیکٹ ٹیمیں جو ${cpBrandName} پر اپنی مصروفیت چلاتی ہیں وہ اپ ڈیٹس فراہم کرنے کا عہد کرتی ہیں تاکہ آپ کو یہ معلوم ہو سکے کہ آپ کے پڑوس کے منصوبے کیسے تیار ہو رہے ہیں۔`,
      defaultConsents: {
        title: 'باخبر رہیں',
        helperText: 'کوئی بھی منتخب کریں',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'تبصرہ کے جوابات',
            description: `کبھی کبھار ٹیم کے ارکان تبصروں کا جواب دے سکتے ہیں۔ اگر وہ آپ کا جواب دیتے ہیں، تو کیا آپ ${cpBrandName} کے ذریعے بھیجے گئے ای میل کے ذریعے مطلع ہونا چاہیں گے؟`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'پروجیکٹ اپڈیٹس',
            description: `ٹیم کے ارکان اس منصوبے پر خبریں اور اپ ڈیٹ پوسٹ کرتے ہیں۔ جب وہ کرتے ہیں، کیا آپ ${cpBrandName} کے ذریعے بھیجے گئے ای میل کے ذریعے مطلع ہونا چاہیں گے؟`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `آپ کے علاقے میں نئی ${cpBrandNamePlural}`,
            description: `${cpBrandName} بہت ساری تنظیموں کے ساتھ کام کرتا ہے تاکہ عظیم جگہوں کو شکل دی جا سکے۔ اگر آپ کے علاقے میں کوئی نیا ${cpBrandName} ہو، تو کیا آپ اپنی اطلاع کے لیے ایک ای میل موصول کرنا چاہیں گے؟`,
          },
        ],
      },
      customConsents: {
        title: 'پروجیکٹ کی مخصوص اجازتیں',
      },
    },
    yo: {
      heading: 'Jeki ni lupu',
      description:
        '{{customerName}} ṣe ileri lati jẹ ki o ni imudojuiwọn lori bi awọn ero fun adugbo rẹ ṣe n dagba.',
      defaultConsents: {
        title: 'Duro ni imọ',
        helperText: '',
        consents: [
          {
            order: 2,
            type: CONSENT_TYPES.COMMENT_RESPONSES,
            enabled: false,
            icon: 'conversationOption',
            heading: 'Ọrọìwòye idahun',
            description: `Nigbakugba awọn ọmọ ẹgbẹ le dahun si awọn asọye. Ti wọn ba dahun si tirẹ, ṣe iwọ yoo fẹ lati gba iwifunni nipasẹ imeeli ti a fi ranṣẹ nipasẹ ${cpBrandName}?`,
          },
          {
            order: 1,
            type: CONSENT_TYPES.PROJECT_NEWS,
            enabled: false,
            icon: 'newspaperOption',
            heading: 'Awọn imudojuiwọn ise agbese',
            description: `Awọn ọmọ ẹgbẹ ẹgbẹ firanṣẹ awọn iroyin ati awọn imudojuiwọn lori iṣẹ akanṣe yii. Nigbati wọn ba ṣe, ṣe iwọ yoo fẹ lati gba iwifunni nipasẹ imeeli ti a firanṣẹ nipasẹ ${cpBrandName}?`,
          },
          {
            order: 3,
            type: CONSENT_TYPES.NEW_COMMONPLACES,
            enabled: false,
            icon: 'mapOption',
            heading: `Tuntun ${cpBrandNamePlural} ni agbegbe rẹ`,
            description: `${cpBrandName} ṣiṣẹ pẹlu ọpọlọpọ awọn ajo lati ṣe apẹrẹ awọn aaye nla. Ti ${cpBrandName} tuntun ba wa ni agbegbe rẹ, ṣe iwọ yoo fẹ lati gba imeeli kan lati jẹ ki o mọ?`,
          },
        ],
      },
      customConsents: {
        title: 'Project kan pato awọn ibaraẹnisọrọ ati awọn ase',
        description:
          'Awọn ifọkansi wọnyi jẹ pato fun iṣẹ akanṣe yii, ti a ṣeto nipasẹ ẹgbẹ akanṣe.',
        helperText: 'Yan eyikeyi',
      },
    },
  })[lang];
