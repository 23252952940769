import * as React from 'react';
import { IconProps } from '../types';

const EmailIcon: React.FC<IconProps> = ({
  width = 21,
  height = 24,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5332 5.00003C2.5332 4.03353 3.3167 3.25003 4.2832 3.25003H17.6165C18.583 3.25003 19.3665 4.03353 19.3665 5.00003V15C19.3665 15.9665 18.583 16.75 17.6165 16.75H4.2832C3.31671 16.75 2.5332 15.9665 2.5332 15V5.00003ZM5.6629 5.08336L10.4195 9.84C10.7124 10.1329 11.1873 10.1329 11.4802 9.84L16.2368 5.08336H5.6629ZM17.5332 6.37973V14.9167H4.36654V6.37973L9.12318 11.1364C10.132 12.1452 11.7677 12.1452 12.7766 11.1364L17.5332 6.37973Z"
      fill="black"
    />
  </svg>
);

export { EmailIcon };
