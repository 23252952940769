import { ProposalStage } from 'Pages/proposals';
import { Question } from 'Client/pages';
import { Icons } from 'Client/pages/planningapps/utils/getTabIcon';
import { Demographics } from './demographics';
import { Contribution, PopulatedAnswer } from './contribution';
import { Page, PageTypes } from './page';
import { Resource, TypedFiles } from './cloudinary';

export type Document = {
  url: string;
  name: string;
  type?: string;
  date?: Date;
  timeToRead?: number;
};

export type Image = {
  url: string;
  name: string;
  isMainImage?: boolean;
};

export enum PlanningAppStage {
  stage1 = 'Stage 1',
  stage2 = 'Stage 2',
  stage3 = 'Stage 3',
  stoppingUpOrder = 'Stopping up order',
  noStrategicIssues = 'No Strategic Issues',
}

export interface PlanningAppContent {
  reference: string;
  status: PlanningAppStatus;
  consultationEnd: Date;
  address: string;
  postcode?: string;
  proposal: string;
  receivedDate: Date;
  validatedDate: Date;
  targetDate: Date;
  decisionDate: Date;
  decision: string;
  applicant: string;
  easting: string;
  northing: string;
  ward: string;
  caseOfficer: string;
  caseOfficerEmail: string;
  storeys: string;
  units: string;
  floorArea: string;
  siteArea: string;
  category: string;
  shortDesc?: string;
  borough?: string;
  stage?: PlanningAppStage;
  glaReference?: string;
  name?: string;
  actualCommittee?: Date;
  mayorMeetingDate?: Date;
  display3d?: boolean | string;
  map?: PlanningAppMapContent;
  questions?: PlanningAppQuestionsId;
  openForComments?: boolean;
  documents?: Document[];
  images?: Image[];
  project_id?: string;
  page_id?: string;
  latestDecisionDate?: Date;
}

export interface PlanningAppPageWithContent extends PlanningAppPage {
  content: PlanningAppContent;
}

export interface PlanningAppPageWithQuestions {
  pageContent: PlanningAppContent;
  questions: PlanningAppQuestions;
}

export interface PlanningAppPageProps {
  pageData: PlanningAppPage;
  pageContent: PlanningAppContent;
  commentsCount: number;
  daysLeftToComment: number;
  questions?: PlanningAppQuestions;
  slug: string;
  initialComments: Array<PlanAppContribution>;
  typedFiles: TypedFiles;
  userDemographics?: PlanAppDemographics;
}

export interface PlanningAppMapContent {
  baseMapSlug: string;
  lat: number;
  lng: number;
  zoom?: number;
}

export interface PlanningAppPageAndContent {
  _id: string;
  projectId: string;
  slug: string;
  stage: ProposalStage;
  type: PageTypes;
  createdAt: Date;
  active: boolean;
  contentVersionId?: string;
  contributionsNumber: number;
  mainImg?: Resource;
  content?: PlanningAppContent;
}
export interface PlanningAppQuestionsId {
  siteRelation: string;
  materialConsiderations: {
    support: string;
    objection: string;
  };
  comment: string;
  viewType: string;
  attachFile?: string;
}

export interface PlanningAppQuestions {
  siteRelation: Question;
  materialConsiderations: {
    support: Question;
    objection: Question;
  };
  comment: Question;
  viewType: Question;
  attachFile?: Question;
}

export type PlanningAppStatus =
  | 'Appeal'
  | 'Consultation open'
  | 'Decision granted'
  | 'Decision refused'
  | 'Awaiting decision'
  | 'Appeal'
  | 'Withdrawn'
  | 'Pending'
  | 'Awaiting committee';

export type PlanningAppCategories =
  | 'Mixed use'
  | 'Green & Public space'
  | 'Commercial & Leisure'
  | 'Infrastructure'
  | 'Industrial'
  | 'Office'
  | 'Retail'
  | 'Residential';

export interface PlanAppDemographics extends Demographics {
  firstName: string;
  lastName: string;
  address: string;
  addressId: string;
  phoneNumber: string;
}

export interface PlanAppContribution extends Contribution {
  answersPopulated: Array<PopulatedAnswer>;
  demographics: PlanAppDemographics;
}

export interface PlanningAppPage {
  _id: string;
  slug: string;
  type: PageTypes.PLANNING_APP;
  projectId: string;
  userId: string;
  active: boolean;
  createdAt: Date | string;
}

export enum PlanningAppsPageTabs {
  LIST = 'list',
  MAP = 'map',
}

export type PlanningAppsTab<T> = T extends PlanningAppsPageTabs.MAP
  ? {
      type: PlanningAppsPageTabs.MAP;
      label: string;
      pageId: string;
      icon?: Icons;
    }
  : {
      type: PlanningAppsPageTabs.LIST;
      label: string;
      icon?: Icons;
    };

export interface PlanningAppsContent<T> {
  title: string;
  description?: string;
  tabs: PlanningAppsTab<T>[];
  filters: {
    title: string;
    type: string;
  }[];
}

export interface PlanningAppsPageWithContentProps<
  T extends PlanningAppsPageTabs,
> extends Page<PlanningAppsContent<T>> {
  planningApps: Array<PlanningAppPageAndContent>;
}

export enum PlanningAppDatabases {
  POSTGRES = 'postgres',
  MONGODB = 'mongodb',
}

export type FetchPlanningAppPageResponse<T> =
  T extends PlanningAppDatabases.POSTGRES
    ? { planningAppPage: PlanningAppContent }
    : {
        planningAppPage: PlanningAppPageWithContent;
      };
