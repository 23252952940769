import { sign } from 'jsonwebtoken';
import { getConfig } from 'Shared/utils/getConfig';
import {
  getUserHighestRakingRole,
  getUserRoleInProject,
} from 'Client/utils/user/getUserRoleInProject';

/**
 * Generate a token for protected API endpoints.
 *
 * @todo Deprecate and remove once new secure login is implemented
 *
 * > wow
 * >
 * > much security
 *
 * ~ Gabo
 */
const generateApiToken = async (project, user) => {
  if (typeof window !== 'undefined') {
    throw new Error('Attempted to run server function on client side!');
  }

  const {
    serverRuntimeConfig: { apiSecret },
  } = getConfig();

  const payload = {
    projectId: project?._id,
    userEmail: user?.email,
    userId: user?._id,
    userRoleInProject: getUserRoleInProject(user, project?._id, project?.name),
    highestRakingRole: getUserHighestRakingRole(user),
  };

  const token = sign(payload, apiSecret, { expiresIn: '3d' });

  return token;
};

export default generateApiToken;
