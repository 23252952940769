import * as React from 'react';
import styled from 'styled-components';
import { Provider as ReduxProvider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { useRouter } from 'next/router';
import Script from 'next/script';
import parser from 'ua-parser-js';
import 'Client/utils/i18n';
import { useMediaQuery } from '@material-ui/core';
import { AppContext, AppProps } from 'next/app';
import { Request } from 'express';
import {
  SessionProvider as NextAuthProvider,
  getSession,
} from 'next-auth/react';
import { NextPageContext } from 'next';
import { Session } from 'next-auth';
import { getConfig } from 'Shared/utils/getConfig';
import { getProjectDetails } from 'Shared/utils/getProjectDetails';
import { BannerContext } from 'Client/context/bannerContext';
import { COOKIES } from 'Client/constants/cookies';
import { FloatingShareButton } from 'Client/components/organisms/FloatingShareButton';
import { isRtlLang } from 'Client/utils/i18n/isRtlLang';
import { LocationListener } from 'Client/utils/analytics';
import { ThemeProvider, mapGaudiBranding } from 'Theme';
import { ProjectContext } from 'Context/projectContext';
import { AuthContext } from 'Context/authContext';
import { AnalyticsProvider } from 'Context/analyticsContext';
import { SharingContextProvider } from 'Context/sharingContext';
import { ProposalContextProvider } from 'Context/proposalContext';
import { Canonical, AccessibilityWidget, SetLocales, StageBanner } from 'Atoms';
import { Sharing } from 'Organisms';
import { ProjectGaudi } from 'Shared/types';
import { CookiePreferences } from 'Shared/types/cookies';
import { DemographicsContextProvider } from 'Client/context/demographicsContext';
import { getDefaultCookiePreferences } from 'Client/utils/cookies/getDefaultCookiePreferences';
import { store } from 'Client/redux-store';
import { Head } from 'Client/templates/components';
import { CookieBanner } from 'Atoms/CookieBanner';
import { LanguageAbv } from 'Shared/types/settings';
import {
  ErrorHandlerProps,
  withErrorHandlers,
} from 'Client/utils/wrappers/appWrappers/withErrorHandlers';
import { SpeedcurveScripts } from 'Client/utils/wrappers/appWrappers/SpeedcurveScripts';
import GqlProvider from 'Client/context/gqlApiContext';
import { RichRequest } from 'Shared/types/middlewares';
import { AppcuesScripts } from 'Client/utils/wrappers/appWrappers/AppcuesScripts';
import { useLogrocket } from 'Client/utils/hooks/useLogrocket';
import { useGleap } from 'Client/utils/hooks/useGleap';
import { LoaderContextProvider } from 'Client/context/loaderContext';
import MapProvider from 'Client/components/map/MapProvider';
import { CustomBrandingTheme } from 'Client/types';
import ErrorCatcher from 'Client/components/organisms/ErrorCatcher/ErrorCatcher';
import generateApiToken from 'Server/utils/generateApiToken';
import { encryptGQL } from 'Shared/utils/encrypt';
import ToastProvider from 'Client/components/Toast/ToastProvider';
import { INextAuthUser } from './api/auth/types';

const {
  publicRuntimeConfig: { env },
} = getConfig();

interface AcornProps extends ErrorHandlerProps {
  initialCustomBranding?: CustomBrandingTheme;
  project: ProjectGaudi;
  deviceType: string;
  isRtl: boolean;
  projectLocales: Array<LanguageAbv>;
  projectDefaultLocale: LanguageAbv;
  cookiePreferences: CookiePreferences;
  displayCookieBanner?: boolean;
  apiToken: string;
  gleapSecret: string;
  session: Session;
  encryptedId: string;
}

interface IPageProps {
  session: Session;
}

type AcornAppType = React.FC<AcornProps & AppProps> & {
  getInitialProps?: (
    ctx: AppContext & {
      ctx: NextPageContext & { req: Request & RichRequest };
    }
  ) => Promise<AcornProps>;
};

const refetchInterval = 600; // Ten minutes

const AcornApp: AcornAppType = ({
  Component,
  pageProps,
  initialCustomBranding,
  project,
  user,
  deviceType,
  isRtl,
  error,
  projectLocales,
  projectDefaultLocale = 'en-GB',
  cookiePreferences,
  displayCookieBanner,
  apiToken,
  gleapSecret,
  session,
  encryptedId,
}) => {
  const router = useRouter();
  const isMobile = useMediaQuery(`(max-width:960px)`);
  const getCookieCondition = () => {
    if (typeof window !== 'undefined') {
      const currentIframeHref = new URL(document.location.href);
      const urlFilePath = decodeURIComponent(currentIframeHref.pathname);
      if (/\/chart\//.test(urlFilePath)) {
        return true;
      }
    }

    return false;
  };

  const isEmbedChart = getCookieCondition();

  const nextAuthSession = (pageProps as IPageProps)?.session || session;

  const nextAuthUserSession = nextAuthSession?.user as INextAuthUser;

  useLogrocket(user || nextAuthUserSession);

  useGleap(user || nextAuthUserSession, gleapSecret);

  React.useEffect(() => {
    const domain = {
      local: '.commonplace.local',
      staging: '.staging.commonplace.dev',
      preProd: '.preprod.commonplace.is',
      live: '.commonplace.is',
    };

    if (nextAuthUserSession && encryptedId.length) {
      // Setting the user id cookie
      document.cookie = `id=${encryptedId}; Domain=${domain[env]}; path=/;`;
    } else {
      // Deleting the cookie if the user is logged out
      document.cookie = `id=; Domain=${domain[env]}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  }, [encryptedId, nextAuthUserSession]);

  const [stageBannerHeight, setStageBannerHeight] = React.useState<number>(0);
  const [errorBannerHeight, setErrorBannerHeight] = React.useState<number>(0);

  React.useEffect(() => {
    const isServerSide = typeof window == 'undefined';

    if (!isServerSide) {
      const stageBanner = document?.querySelector('#stage-banner');
      const errorBanner = document?.querySelector('#error-banner');

      if (stageBanner) setStageBannerHeight(stageBanner.clientHeight / 16); // px to rem

      if (errorBanner) setErrorBannerHeight(errorBanner.clientHeight / 16);
    }
  }, []);

  return (
    <ErrorCatcher>
      <NextAuthProvider
        session={nextAuthSession}
        refetchInterval={refetchInterval}
        refetchOnWindowFocus
      >
        <GqlProvider apiToken={apiToken}>
          <SpeedcurveScripts />
          {!isEmbedChart && (
            <Script
              id="usercentrics-cmp"
              src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
              data-settings-id="mtjDiRWdO0qpgX"
              async
            />
          )}
          <AppcuesScripts
            user={user || nextAuthUserSession}
            project={project}
          />
          <ReduxProvider store={store}>
            {projectLocales && (
              <SetLocales
                projectLocales={projectLocales}
                projectDefaultLocale={projectDefaultLocale}
              />
            )}
            <div dir={isRtl ? 'rtl' : 'ltr'}>
              <ThemeProvider
                customBrandingTheme={initialCustomBranding}
                deviceType={deviceType}
                isRtl={isRtl}
              >
                <ToastProvider>
                  {displayCookieBanner &&
                    !/\/map|\/chart\//.test(router.asPath) && <CookieBanner />}
                  <ProjectContext.Provider value={project}>
                    <Head cookies={cookiePreferences} />
                    {error && (
                      <ErrorHeader id="error-banner">
                        {error.message}
                      </ErrorHeader>
                    )}
                    {!/\/chart\//.test(router.asPath) && (
                      <StageBanner
                        stage={project?.stage}
                        role={user?.role?.name}
                      />
                    )}
                    <AuthContext.Provider value={user || nextAuthUserSession}>
                      <BannerContext.Provider
                        value={{ errorBannerHeight, stageBannerHeight }}
                      >
                        <AnalyticsProvider
                          user={user || nextAuthUserSession}
                          project={project}
                        >
                          <SharingContextProvider>
                            <CookiesProvider>
                              <LoaderContextProvider>
                                <ProposalContextProvider>
                                  <DemographicsContextProvider>
                                    <MapProvider>
                                      <LocationListener />
                                      <Canonical />
                                      <Component {...pageProps} />
                                      <FloatingShareButton />
                                      <Sharing>
                                        {isMobile ? (
                                          <Sharing.Drawer />
                                        ) : (
                                          <Sharing.Modal />
                                        )}
                                      </Sharing>
                                    </MapProvider>
                                  </DemographicsContextProvider>
                                </ProposalContextProvider>
                              </LoaderContextProvider>
                            </CookiesProvider>
                          </SharingContextProvider>
                        </AnalyticsProvider>
                      </BannerContext.Provider>
                    </AuthContext.Provider>
                  </ProjectContext.Provider>
                </ToastProvider>
              </ThemeProvider>
              <AccessibilityWidget
                deviceType={deviceType}
                isRtl={isRtl}
                user={user || nextAuthUserSession}
                project={project}
              />
            </div>
          </ReduxProvider>
        </GqlProvider>
      </NextAuthProvider>
    </ErrorCatcher>
  );
};

const App: AcornAppType = withErrorHandlers(AcornApp);

const ErrorHeader = styled.header`
  background: ${({ theme }) => theme.colorMappings.error};
  color: white;
  text-align: center;
  font-size: 1rem;
`;

App.getInitialProps = async ({ ctx, router: { locale } }) => {
  const { getGaudiAppSettings, getProjectLocales, getProjectDefaultLocale } =
    await import('../src/server/services/projects');

  const {
    serverRuntimeConfig: { gleap: gleapSecret },
  } = getConfig();

  const { user, headers, query, universalCookies, connection } = ctx.req;

  const { res } = ctx;

  const projectName = ctx.req.headers.host.split('.')[0];
  const project = await getProjectDetails(projectName);

  global.projectName = projectName;

  // to handle gaudi specific behaviour
  if (query?.lang) {
    const targetURL = `/${query.lang}`;
    res.writeHead(307, { Location: targetURL });
    res.end();
    return;
  }

  const projectLocales = await getProjectLocales(project?._id);
  const projectDefaultLocale = await getProjectDefaultLocale(project?._id); // can be undefined
  const deviceType = parser(headers['user-agent']).device.type || 'desktop';
  const initialCustomBranding = project?.brandingOptions
    ? mapGaudiBranding(project.brandingOptions)
    : undefined;
  // todo: update projectLocale based on route vs defaultLocale
  // eg when defaultLocale is not english, redirect immediately to /defaultLocale (PA-646)
  const projectLocale = locale || project.locale || 'en-GB';
  const isRtl = isRtlLang(projectLocale);
  const emergencyAppSettings =
    project && (await getGaudiAppSettings(project?.id, 'EMERGENCY_BANNER'));

  let error: AcornProps['error'];
  if (emergencyAppSettings) {
    error = {
      message: emergencyAppSettings.data[projectLocale],
    };
  }

  if (!project || !project?.features?.acorn) {
    const forwarded = headers['x-forwarded-for'];
    error = {
      message: projectName,
      ip: forwarded
        ? String(forwarded)?.split(/, /)[0]
        : connection?.remoteAddress,
      unknownProject: true,
      gaudiProject: project?.features && !project.features?.acorn,
    };
  }

  const commonplaceCookiePreferences: CookiePreferences = universalCookies?.get(
    COOKIES.COMMONPLACE_COOKIE_PREFERENCES
  );

  const cookiePreferences = getDefaultCookiePreferences({
    userPreferences: commonplaceCookiePreferences,
    enabled: !project?.features?.cookieNotification,
  });

  const session = await getSession(ctx);
  const apiToken = await generateApiToken(project, user || session?.user);

  const encryptedId = session
    ? await encryptGQL(apiToken, (session.user as INextAuthUser)._id)
    : '';

  return {
    user,
    project,
    cookiePreferences,
    initialCustomBranding,
    deviceType,
    isRtl,
    error,
    projectLocales,
    projectDefaultLocale,
    displayCookieBanner:
      !commonplaceCookiePreferences && project?.features?.cookieNotification,
    apiToken,
    gleapSecret,
    session,
    encryptedId,
  };
};

export default App;
